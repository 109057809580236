// @flow
import '@babel/polyfill';
import 'core-js/features/';
import 'whatwg-fetch';
import 'canvas-toBlob';
import { main } from './app';

const APP_ID = 'reacttemplate';

require('./child-node-remove').polyfill();

fetch('app.settings.json')
  .then((response) => response.json())
  .then((appSettings) => {
    main(APP_ID, Object.freeze(appSettings));
  });
