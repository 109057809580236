// @flow
import { DsLogoIcon, DsWheelIcon } from '@dealersocket/ds-ui-react/logos';
import React from 'react';

export const titleLogos = [
  {
    key: 'DsLogoIcon',
    large: <DsLogoIcon />,
    small: <DsWheelIcon />,
  },
];
