// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  sliceName,
  TOGGLE_LEFTNAV,
  SET_LEFTNAV_CHANGED_BY_USER,
  TOGGLE_LEFTNAV_EXPANDED,
} from './left-nav.types';

const initialState = {
  open: false,
  changedByUser: false,
  expanded: false,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case TOGGLE_LEFTNAV:
      return {
        ...state,
        open: !state.open,
      };
    case SET_LEFTNAV_CHANGED_BY_USER:
      return {
        ...state,
        changedByUser: true,
      };
    case TOGGLE_LEFTNAV_EXPANDED:
      if (state.expanded !== action.payload) {
        return {
          ...state,
          expanded: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
