// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DsLogoProgress } from '@dealersocket/ds-ui-react/DsLogoProgress';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { axiosApi, getAppSettings } from '@dealersocket/react-common';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { productEnum } from 'shared/utils/enums';
import { getUserDealerships } from 'shared/utils/user-helpers';
import { ConnectedBanner } from 'shared/components/banner/banner-container';
import { ProductCard } from 'shared/components/card/product-card/product-card.component';
import { setBannerAction } from 'shared/components/banner/state/banner.actions';
import { ProductLinking } from 'shared/components/link-product/product-linking.component';
import { selectedUserSelector } from '../../../users/state/users.selectors';
import { loadAccountsSettingsThunk } from '../../../users/state/users.thunks';
import {
  AccountDealershipTable,
  getAccountDealershipTableRows,
} from './account-dealership-table.component';

const styles = (theme) => {
  return {
    cardContainer: {
      width: '100%',
    },
    contentContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 630,
      [theme.breakpoints.only('xs')]: {
        margin: 'inherit',
      },
    },
    footer: {
      paddingTop: 110,
      textAlign: 'center',
    },
    grid: {
      width: '100%',
    },
    root: {
      flexGrow: 1,
      marginLeft: '15%',
      marginRight: '15%',
      [theme.breakpoints.only('xs')]: {
        height: '100%',
        margin: 0,
        padding: 10,
        width: '100%',
      },
    },
    text: {
      color: theme.palette.grey[700],
    },
    titleContainer: {
      marginTop: 60,
      [theme.breakpoints.only('xs')]: {
        margin: 'inherit',
        paddingLeft: 27,
        paddingTop: 20,
      },
    },
  };
};

type InternalProps = {
  classes: JssClasses,
};

const ProductAccessComponent = (props: InternalProps) => {
  const { classes } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => selectedUserSelector(state));

  const [linkableProducts, setLinkableProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [loading, setLoading] = useState(true);

  const getLinkedProducts = (id: string) => {
    setLoading(true);
    return axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${id}/GetLinkableProducts`
    )
      .then((result) => {
        const mappedResults = result.map((r) => ({
          ...r,
          displayName: productEnum(r.type),
        }));

        mappedResults.sort((r1, r2) => {
          if (r1.displayName > r2.displayName) return 1;
          if (r1.displayName < r2.displayName) return -1;
          return 0;
        });

        setLinkableProducts(mappedResults);
      })
      .then(() => setLoading(false))
      .catch((error) => {
        dispatch(
          setBannerAction({
            message:
              'Unable to get linked products for user. Please try again.',
            type: 'error',
            bannerAction: () => getLinkedProducts(id),
          })
        );
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (!user) {
      dispatch(loadAccountsSettingsThunk());
    }
  }, []);

  useEffect(() => {
    if (user) {
      getLinkedProducts(user.id);
    }
  }, [user]);

  const renderProductCard = (linkableProduct: any) => {
    return (
      <ProductCard
        product={linkableProduct}
        expanded={selectedProduct === linkableProduct.type}
        content={
          linkableProduct.linked ? (
            <AccountDealershipTable
              rows={getAccountDealershipTableRows(
                getUserDealerships(user),
                linkableProduct.type
              )}
            />
          ) : (
            <ProductLinking
              onCancel={() => setSelectedProduct('')}
              selectedProduct={linkableProduct}
              onRefresh={() => {
                dispatch(loadAccountsSettingsThunk());
                getLinkedProducts(user.id).then(() => setSelectedProduct(''));
              }}
            />
          )
        }
        onClick={(productType) => () =>
          productType === selectedProduct
            ? setSelectedProduct('')
            : setSelectedProduct(linkableProduct.type)}
      />
    );
  };

  const productsComponent = linkableProducts.map((linkableProduct) => {
    return (
      <Grid
        item
        classes={{ root: classes.grid }}
        key={`${linkableProduct.type}-grid-item`}
      >
        <div className={classes.cardContainer}>
          {renderProductCard(linkableProduct)}
        </div>
      </Grid>
    );
  });

  return (
    <>
      {!md && <ConnectedBanner />}
      <div className={classes.root}>
        <div className={classes.contentContainer}>
          <div className={classes.titleContainer}>
            <Typography paragraph variant={TypographyVariants.H4}>
              DealerSocket Access
            </Typography>
          </div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <DsLogoProgress />
            </div>
          ) : (
            <Grid container spacing={2}>
              {productsComponent}
            </Grid>
          )}

          <div className={classes.footer} data-e2e="product-access-footer">
            <Typography
              className={classes.text}
              paragraph
              variant={TypographyVariants.H5}
            >
              Need Help? Contact DealerSocket Support!
            </Typography>
            <Typography
              className={classes.text}
              variant={TypographyVariants.H6}
            >
              {intl.locale.toLowerCase() === 'en-au'
                ? '+61 3 9535 2380'
                : '(888) 988—6444'}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProductAccess = withStyles(styles)(ProductAccessComponent);
