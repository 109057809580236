// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

export function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-${index}`,
  };
}

export const LinkTab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    fontSize: 16,
    fontWeight: 'bold',
    minHeight: 52,
    '&:focus': {
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      border: 0,
    },
  },
}))((props) => (
  <Tab
    component="a"
    onClick={(event) => {
      event.preventDefault();
    }}
    {...props}
  />
));
