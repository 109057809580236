// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  APPLY_FILTERS,
  CLEAR_UNAPPLIED_FILTER,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  LOAD_ACCOUNT_SETTINGS,
  LOAD_ACCOUNT_SETTINGS_SUCCESS,
  LOAD_USER_SUCCESS,
  LOAD_USERS_SUCCESS,
  RESET_FILTERS,
  SELECT_USER,
  SET_EXPANDED_FILTER,
  SET_FILTER,
  SET_IS_BUSY,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  SET_USER_STATUS,
  SET_USER_STATUS_FAILED,
  SET_USER_STATUS_SUCCESS,
  SET_WILL_REDIRECT,
  sliceName,
} from './users.types';

export const initialState = {
  selectedUser: null,
  users: [],
  totalUsers: 0,
  unconvertedUsernameRate: 0,
  usersLoaded: false,
  expandedFilter: null,
  isBusy: false,
  willRedirect: false,
  usersPageTableOptions: {
    page: 0,
    limit: 25,
    orderBy: 0,
    filterTerm: '',
    sortOrder: 'asc',
    sortTerm: 'name',
    selectedStatus: 'Active',
    appliedStatus: 'Active',
    selectedDepartments: [],
    appliedDepartments: [],
    selectedDealerships: [],
    appliedDealerships: [],
    selectedLoginRoles: [],
    appliedLoginRoles: [],
  },
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case APPLY_FILTERS:
      return {
        ...state,
        usersPageTableOptions: {
          ...state.usersPageTableOptions,
          appliedDepartments: [
            ...state.usersPageTableOptions.selectedDepartments,
          ],
          appliedDealerships: [
            ...state.usersPageTableOptions.selectedDealerships,
          ],
          appliedStatus: state.usersPageTableOptions.selectedStatus,
          appliedLoginRoles: state.usersPageTableOptions.selectedLoginRoles,
        },
      };
    case CLEAR_UNAPPLIED_FILTER:
      return {
        ...state,
        usersPageTableOptions: {
          ...state.usersPageTableOptions,
          selectedStatus: state.usersPageTableOptions.appliedStatus,
          selectedDepartments: state.usersPageTableOptions.appliedDepartments,
          selectedDealerships: state.usersPageTableOptions.appliedDealerships,
          selectedLoginRoles: state.usersPageTableOptions.appliedLoginRoles,
        },
      };
    case DELETE_USER:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        isDeleting: false,
      };
    case LOAD_ACCOUNT_SETTINGS:
      return {
        ...state,
        selectedUser: null,
      };
    case LOAD_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        usersLoaded: true,
        users: action.payload.users,
        totalUsers: action.payload.totalUsers,
        unconvertedUsernameRate: action.payload.unconvertedUsernameRate,
      };
    case RESET_FILTERS:
      return {
        ...state,
        usersPageTableOptions: {
          ...state.usersPageTableOptions,
          selectedStatus: initialState.usersPageTableOptions.selectedStatus,
          appliedStatus: initialState.usersPageTableOptions.appliedStatus,
          selectedDepartments:
            initialState.usersPageTableOptions.selectedDepartments,
          appliedDepartments:
            initialState.usersPageTableOptions.appliedDepartments,
          selectedDealerships:
            initialState.usersPageTableOptions.selectedDealerships,
          appliedDealerships:
            initialState.usersPageTableOptions.appliedDealerships,
          selectedLoginRoles:
            initialState.usersPageTableOptions.selectedLoginRoles,
          appliedLoginRoles:
            initialState.usersPageTableOptions.appliedLoginRoles,
        },
      };
    case SELECT_USER:
      if (state.selectedUser !== action.payload) {
        return {
          ...state,
          selectedUser: action.payload,
        };
      }
      return state;
    case SET_EXPANDED_FILTER:
      return { ...state, expandedFilter: action.payload };
    case SET_FILTER: {
      const { filter, value } = action.payload;
      if (filter) {
        return {
          ...state,
          usersPageTableOptions: {
            ...state.usersPageTableOptions,
            [`selected${filter}`]: value,
          },
        };
      }
      return { ...state };
    }
    case SET_IS_BUSY: {
      return {
        ...state,
        isBusy: action.payload,
      };
    }
    case SET_PAGE:
      if (action.payload !== state.usersPageTableOptions.page) {
        return {
          ...state,
          usersPageTableOptions: {
            ...state.usersPageTableOptions,
            page: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    case SET_PAGE_SIZE:
      if (action.payload !== state.usersPageTableOptions.limit) {
        return {
          ...state,
          usersPageTableOptions: {
            ...state.usersPageTableOptions,
            limit: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SEARCH_TERM:
      if (action.payload !== state.usersPageTableOptions.filterTerm) {
        return {
          ...state,
          usersPageTableOptions: {
            ...state.usersPageTableOptions,
            filterTerm: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SORT_ORDER: {
      let orderBy;
      if (state.usersPageTableOptions) {
        ({ orderBy } = state.usersPageTableOptions);
      }
      const { sortOrder, sortTerm } = action.payload;
      switch (sortTerm) {
        case 'name':
          orderBy = sortOrder === 'asc' ? 0 : 1;
          break;
        case 'userName':
          orderBy = sortOrder === 'asc' ? 2 : 3;
          break;
        case 'department':
          orderBy = sortOrder === 'asc' ? 4 : 5;
          break;
        case 'lastLogin':
          orderBy = sortOrder === 'asc' ? 6 : 7;
          break;
        default:
          orderBy = undefined;
      }
      if (state.usersPageTableOptions.orderBy !== orderBy) {
        return {
          ...state,
          usersPageTableOptions: {
            ...state.usersPageTableOptions,
            orderBy,
            sortOrder,
            sortTerm,
          },
        };
      }
      return {
        ...state,
      };
    }
    case SET_USER_STATUS: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case SET_USER_STATUS_FAILED: {
      return {
        ...state,
        isBusy: false,
      };
    }
    case SET_USER_STATUS_SUCCESS: {
      return {
        ...state,
        isBusy: false,
      };
    }
    case SET_WILL_REDIRECT: {
      return {
        ...state,
        willRedirect: action.payload,
      };
    }
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
