// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  SET_ENTITIES_LOADING,
  SET_INTENDED_ROUTE,
  SET_INTENDED_ROW,
  SET_STEP_INDEX,
  sliceName,
} from './page.types';

const initialState = {
  intendedRow: null,
  intendedRoute: null,
  stepIndex: 0,
  isDeleting: false,
  entitiesLoading: false,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_ENTITIES_LOADING:
      return {
        ...state,
        entitiesLoading: action.payload,
      };
    case SET_INTENDED_ROUTE:
      if (state.intendedRoute !== action.payload) {
        return {
          ...state,
          intendedRoute: action.payload,
        };
      }
      return state;
    case SET_INTENDED_ROW:
      return {
        ...state,
        intendedRow: action.payload,
      };
    case SET_STEP_INDEX:
      return {
        ...state,
        stepIndex: action.payload,
      };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
