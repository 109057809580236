// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  CHECK_USERNAME,
  CHECK_USERNAME_FAILED,
  CHECK_USERNAME_SUCCESS,
  ENABLE_RESET_PASSWORD,
  sliceName,
  RESET_ACCOUNT,
  RESET_ACCOUNT_FAILED,
  RESET_PASSWORD,
  SEND_ACCOUNT_SETUP,
  SEND_ACCOUNT_SETUP_SUCCESS,
  SEND_EMAIL_VERIFICATION,
  SEND_EMAIL_VERIFICATION_FAILED,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SET_DUPLICATE_PASSWORD,
  SET_CHANGE_PASSWORD_DIALOG,
  SET_INVALID_PASSWORD,
  SEND_ACCOUNT_SETUP_FAILED,
} from './account-settings.types';

const initialState = {
  disableResetPassword: false,
  disableSendAccountSetup: false,
  disableSave: false,
  disableUnlink: false,
  disableResetAccount: false,
  duplicatePassword: false,
  flippedCards: [],
  invalidPassword: false,
  isSubmittingEmail: false,
  openChangePasswordDialog: false,
  product: null,
  uniqueUsername: true,
  unlinkConfirmDialogOpen: false,
  user: null,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case CHECK_USERNAME:
      return {
        ...state,
        disableSave: true,
      };
    case CHECK_USERNAME_FAILED:
      return {
        ...state,
        disableSave: false,
        uniqueUsername: false,
      };
    case CHECK_USERNAME_SUCCESS:
      return {
        ...state,
        disableSave: false,
        uniqueUsername: action.payload,
      };
    case ENABLE_RESET_PASSWORD:
      return {
        ...state,
        disableResetPassword: !action.payload,
      };
    case RESET_ACCOUNT:
      return {
        ...state,
        disableResetAccount: true,
      };
    case RESET_ACCOUNT_FAILED:
      return {
        ...state,
        disableResetAccount: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        disableResetPassword: true,
      };

    case SEND_ACCOUNT_SETUP:
      return {
        ...state,
        disableSendAccountSetup: true,
      };
    case SEND_ACCOUNT_SETUP_FAILED:
      return {
        ...state,
        disableSendAccountSetup: false,
      };
    case SEND_ACCOUNT_SETUP_SUCCESS:
      return {
        ...state,
        disableSendAccountSetup: false,
      };
    case SEND_EMAIL_VERIFICATION:
      return {
        ...state,
        isSubmittingEmail: true,
      };
    case SEND_EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        isSubmittingEmail: false,
      };
    case SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isSubmittingEmail: false,
      };
    case SET_CHANGE_PASSWORD_DIALOG:
      return {
        ...state,
        duplicatePassword: false,
        invalidPassword: false,
        openChangePasswordDialog: action.payload,
      };
    case SET_DUPLICATE_PASSWORD:
      return {
        ...state,
        duplicatePassword: action.payload,
      };
    case SET_INVALID_PASSWORD:
      return {
        ...state,
        invalidPassword: action.payload,
      };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
