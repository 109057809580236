/* eslint-disable max-lines */
// @flow
import { Dispatch } from 'redux';
import { reset, setSubmitFailed, startSubmit, stopSubmit } from 'redux-form';
import debounce from '@material-ui/core/utils/debounce';
import { axiosApi, getAppSettings } from '@dealersocket/react-common';
import { setEntitiesLoadingAction } from 'shared/components/page/state/page.actions';
import {
  clearBannerAction,
  setBannerAction,
} from 'shared/components/banner/state/banner.actions';
import { formattedEditInfoUserBody } from 'shared/utils/format-user';
import { formatUserFilters } from 'shared/utils/format-filter';
import { productToIntEnum } from 'shared/utils/enums';
import {
  usersPageTableOptionsSelector,
  selectedUserFormattedSelector,
} from './users.selectors';
import { userProfileInfoFormName } from '../users-page/user-edit/form-name';
import {
  applyFiltersAction,
  deleteUserFailedAction,
  deleteUserSuccessAction,
  loadAccountSettingsAction,
  loadAccountSettingsSuccessAction,
  loadUsersSuccessAction,
  loadUserSuccessAction,
  resetFiltersAction,
  selectUserAction,
  setPageAction,
  setPageSizeAction,
  setSearchTermAction,
  setSortOrderAction,
  deleteUserAction,
  setUserStatusAction,
  setUserStatusFailedAction,
  setWillRedirectAction,
  setIsBusyAction,
} from './users.actions';
import { openDialogAction } from '../../../shared/redux-dialog/state/redux-dialog.actions';
import { customerReassignmentConfirmDialogName } from '../../../shared/constants';
import { setReassignmentInfoAction } from '../../../shared/components/customer-reassignment/state/customer-reassignment.actions';
import { isOpenSelector } from '../../../shared/redux-dialog/state/redux-dialog.selectors';
import {
  getUserDealerships,
  getUserDealershipProducts,
} from '../../../shared/utils/user-helpers';
import {
  getCrmHasUserEvents,
  getCrmIsDefaultDealershipUser,
  getCrmDealershipUser,
  postCrmDealershipUser,
} from '../../../shared/utils/crm-api-helpers';

export function applyFiltersThunk() {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(applyFiltersAction());
    dispatch(searchUsersThunk());
  };
}

export function deleteUserThunk(id: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteUserAction());
    await axiosApi(`${getAppSettings().ssoApiUrl}/Users/${id}`, {
      method: 'delete',
    })
      .then(() => dispatch(deleteUserSuccessAction()))
      .catch((error) => {
        dispatch(
          setBannerAction({
            message: 'Unable to delete user record. Please try again.',
            type: 'error',
            bannerAction: deleteUserThunk(id),
          })
        );
        dispatch(deleteUserFailedAction());
        return Promise.reject(error);
      });
  };
}

const hasDefaultDealershipErrorMessage = (defaultDealership: any): string => {
  return `This user is the default CRM user for ${
    defaultDealership.name
  }. A new default user must be selected in the Dealership Contact Information page for ${
    defaultDealership.name
  } before this user can be deactivated.`;
};

export function removeAccessThunk(id: string, checkEvents: boolean = true) {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return dispatch(loadUserThunk(id)).then(() => {
      const user = selectedUserFormattedSelector(getState());
      dispatch(setIsBusyAction(true));

      getDefaultDealership(user).then(async (defaultDealership) => {
        if (defaultDealership) {
          dispatch(setIsBusyAction(false));
          dispatch(onHasDefaultDealershipThunk(defaultDealership));
        } else {
          axiosApi(`${getAppSettings().ssoApiUrl}/Users/${id}/removeAccess`, {
            method: 'post',
          })
            .then((response) => {
              if (checkEvents) {
                if (user === null) {
                  return;
                }
                dispatch(checkForCustomerReassignmentThunk(user));
              } else {
                dispatch(reloadUserOrRedirectThunk(id));
              }

              response.dealershipProductUserIdsRemoved.forEach(
                (removedDpuId) => {
                  let dealershipProduct = user.dealerships
                    .flatMap((d) => d.dealershipProducts)
                    .filter((dp) => dp.id === removedDpuId);
                  dealershipProduct =
                    dealershipProduct.length > 0 ? dealershipProduct[0] : null;

                  // Deactivate dealership users for removed crm dealership products
                  if (
                    dealershipProduct &&
                    dealershipProduct.productId === productToIntEnum('crm')
                  ) {
                    dispatch(
                      deactivateDealershipUserThunk(
                        dealershipProduct.identifier,
                        dealershipProduct.productInfo.id
                      )
                    );
                  }
                }
              );
            })
            .catch((error) => {
              dispatch(setIsBusyAction(false));
              let errorMessage = 'Unable to remove access. Please try again.';

              if (error && error.response && error.response.data) {
                errorMessage = error.response.data;
              }

              dispatch(
                setBannerAction({
                  message: errorMessage,
                  type: 'error',
                  bannerAction: removeAccessThunk(id),
                })
              );
              return Promise.reject(error);
            })
            .finally(() => {
              dispatch(reloadUserOrRedirectThunk(id));
            });
        }
      });
    });
  };
}

export function removeAccountAccessThunk(
  id: string,
  dealershipProductId: string,
  productUsername: string,
  productSiteId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIsBusyAction(true));
    dispatch(loadUserThunk(id)).then(async (user) => {
      let dealershipProduct: any = user.dealerships
        .flatMap((d) => d.dealershipProducts)
        .filter((dp) => dp.id === dealershipProductId);
      dealershipProduct =
        dealershipProduct.length > 0 ? dealershipProduct[0] : null;

      if (
        dealershipProduct &&
        dealershipProduct.productId === productToIntEnum('crm')
      ) {
        getDefaultDealership(user).then(async (defaultDealership) => {
          if (
            defaultDealership &&
            defaultDealership.id === dealershipProductId
          ) {
            dispatch(setIsBusyAction(false));
            dispatch(onHasDefaultDealershipThunk(defaultDealership));
          } else {
            await axiosApi(
              `${
                getAppSettings().ssoApiUrl
              }/Users/${id}/removeAccountAccess?dealershipProductId=${dealershipProductId}`,
              {
                method: 'post',
              }
            )
              .then(() => {
                getCrmHasUserEvents(
                  dealershipProduct && dealershipProduct.identifier,
                  productUsername
                )
                  .then((hasEvents) => {
                    if (hasEvents) {
                      dispatch(
                        setReassignmentInfoAction({
                          siteId: productSiteId,
                          username: productUsername,
                        })
                      );
                      dispatch(
                        openDialogAction(customerReassignmentConfirmDialogName)
                      );
                    }
                    dispatch(
                      deactivateDealershipUserThunk(
                        dealershipProduct && dealershipProduct.identifier,
                        dealershipProduct.productInfo.id
                      )
                    );
                  })
                  .catch((error) => {
                    return Promise.reject(error);
                  });
              })
              .catch((error) => {
                dispatch(setIsBusyAction(false));
                let errorMessage = 'Unable to remove access. Please try again.';

                if (error && error.response && error.response.data) {
                  errorMessage = error.response.data;
                }

                dispatch(
                  setBannerAction({
                    message: errorMessage,
                    type: 'error',
                    bannerAction: removeAccountAccessThunk(
                      id,
                      dealershipProductId,
                      productUsername,
                      productSiteId
                    ),
                  })
                );
                return Promise.reject(error);
              })
              .finally(() => {
                dispatch(reloadUserOrRedirectThunk(id));
              });
          }
        });
      } else {
        await axiosApi(
          `${
            getAppSettings().ssoApiUrl
          }/Users/${id}/removeAccountAccess?dealershipProductId=${dealershipProductId}`,
          {
            method: 'post',
          }
        )
          .catch((error) => {
            dispatch(setIsBusyAction(false));
            let errorMessage = 'Unable to remove access. Please try again.';

            if (error && error.response && error.response.data) {
              errorMessage = error.response.data;
            }

            dispatch(
              setBannerAction({
                message: errorMessage,
                type: 'error',
                bannerAction: removeAccountAccessThunk(
                  id,
                  dealershipProductId,
                  productUsername,
                  productSiteId
                ),
              })
            );
            return Promise.reject(error);
          })
          .finally(() => {
            dispatch(reloadUserOrRedirectThunk(id));
          });
      }
    });
  };
}

export function setUserStatusThunk(
  id: string,
  disabled: boolean,
  checkEvents: boolean = true
) {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return dispatch(loadUserThunk(id)).then(async () => {
      const user = selectedUserFormattedSelector(getState());

      dispatch(setUserStatusAction());
      if (disabled) {
        getDefaultDealership(user).then((defaultDealership) => {
          // Do not disable enterprise account or product accounts if user is a default user
          if (defaultDealership) {
            dispatch(setUserStatusFailedAction());

            dispatch(onHasDefaultDealershipThunk(defaultDealership));
          } else {
            axiosApi(
              `${
                getAppSettings().ssoApiUrl
              }/Users/${id}/setUserStatus?disabled=${disabled}`,
              {
                method: 'post',
              }
            )
              .then(() => {
                if (checkEvents) {
                  dispatch(checkForCustomerReassignmentThunk(user));
                }
                dispatch(deactivateAllDealershipUsersThunk(user));
              })
              .catch((error) => {
                dispatch(setUserStatusFailedAction());
                let errorMessage =
                  'Unable to change user status. Please try again.';
                if (error && error.response && error.response.data) {
                  errorMessage = error.response.data;
                }
                dispatch(
                  setBannerAction({
                    message: errorMessage,
                    type: 'error',
                    bannerAction: setUserStatusThunk(id, disabled),
                  })
                );
                return Promise.reject(error);
              })
              .finally(() => {
                dispatch(reloadUserOrRedirectThunk(id));
              });
          }
        });
      } else {
        axiosApi(
          `${
            getAppSettings().ssoApiUrl
          }/Users/${id}/setUserStatus?disabled=${disabled}`,
          {
            method: 'post',
          }
        )
          .catch((error) => {
            dispatch(setUserStatusFailedAction());
            let errorMessage =
              'Unable to change user status. Please try again.';
            if (error && error.response && error.response.data) {
              errorMessage = error.response.data;
            }
            dispatch(
              setBannerAction({
                message: errorMessage,
                type: 'error',
                bannerAction: setUserStatusThunk(id, disabled),
              })
            );
            return Promise.reject(error);
          })
          .finally(() => {
            dispatch(reloadUserOrRedirectThunk(id));
          });
      }
    });
  };
}

function onHasDefaultDealershipThunk(defaultDealership: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    return dispatch(
      setBannerAction({
        message: hasDefaultDealershipErrorMessage(defaultDealership),
        type: 'error',
      })
    );
  };
}

// Checks each of the user's crm products to see if the user
// is the dealership's default user.
function getDefaultDealership(user: any) {
  const promises = [];
  getUserDealershipProducts(user, productToIntEnum('crm')).forEach(
    (crmProduct) => {
      promises.push(
        getCrmIsDefaultDealershipUser(
          crmProduct.identifier,
          crmProduct.productInfo.id
        ).then((isDefaultDealershipUser) => {
          return isDefaultDealershipUser ? crmProduct : null;
        })
      );
    }
  );

  return Promise.all(promises)
    .then((values) => {
      const defaultDealerships = values.filter((v) => v !== null);
      return defaultDealerships.length > 0 ? defaultDealerships[0] : null;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
// Deactivate DealershipUsers for all crm accounts for the given user
// This will automatically deactivate the credit user associated with this user
function deactivateAllDealershipUsersThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const crmProducts = getUserDealerships(user).flatMap((dealership) =>
      dealership.dealershipProducts.filter(
        (dealershipProduct) => dealershipProduct.productInfo.type === 1
      )
    );

    const promises = [];
    crmProducts.forEach((crmProduct) => {
      promises.push(
        dispatch(
          deactivateDealershipUserThunk(
            crmProduct.identifier,
            crmProduct.productInfo.id
          )
        )
      );
    });

    Promise.all(promises)
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch(reloadUserOrRedirectThunk(user.id));
      });
  };
}

// Deactivate DealershipUsers for the given crm account and site ID
// This will automatically deactivate the credit user associated with this user
function deactivateDealershipUserThunk(siteId: string, crmId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    return getCrmDealershipUser(siteId, crmId).then((dealershipUser) => {
      postCrmDealershipUser(siteId, crmId, {
        ...dealershipUser,
        isActive: false,
      }).catch((error) => {
        // If the CRM API is unable to update the dealership user, display the error
        if (error.response.status === 400) {
          dispatch(
            setBannerAction({
              message: error.response.data,
              type: 'error',
            })
          );
        }
      });
    });
  };
}

export function editUserThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const { ...formattedBody } = formattedEditInfoUserBody(user);

    dispatch(startSubmit(userProfileInfoFormName));
    return axiosApi(`${getAppSettings().ssoApiUrl}/Users/${user.id}`, {
      method: 'put',
      data: JSON.stringify(formattedBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((payload) => dispatch(selectUserAction(payload)))
      .then(() => dispatch(reset(userProfileInfoFormName)))
      .then(() => dispatch(loadUserThunk(user.id)))
      .then(() => dispatch(stopSubmit(userProfileInfoFormName)))
      .catch((error) => {
        dispatch(setSubmitFailed(userProfileInfoFormName));
        let errorMessage = 'Unable to save user record. Please try again.';
        if (error && error.response && error.response.data) {
          errorMessage = error.response.data;
        }
        dispatch(
          setBannerAction({
            message: errorMessage,
            type: 'error',
            bannerAction: editUserThunk(user),
          })
        );
        return Promise.reject(error);
      });
  };
}

export function loadUserThunk(id: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    const result = await axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${id}`
    ).catch((error) => {
      dispatch(
        setBannerAction({
          message: 'Unable to load user. Please try again.',
          type: 'error',
          bannerAction: loadUserThunk(id),
        })
      );
      return Promise.reject(error);
    });

    dispatch(loadUserSuccessAction(result));
    return result;
  };
}

export function loadAccountsSettingsThunk() {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    dispatch(loadAccountSettingsAction());
    const result = await axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${getState().oidc.user.profile.sub}`
    )
      .then((payload) => dispatch(loadAccountSettingsSuccessAction(payload)))
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          dispatch(clearBannerAction());
          dispatch(setWillRedirectAction(true));
        } else {
          dispatch(
            setBannerAction({
              message: 'Unable to load profile information. Please try again.',
              type: 'error',
              bannerAction: loadAccountsSettingsThunk(),
            })
          );
        }
        return Promise.reject(error);
      });
    return result;
  };
}

export function loadUsersThunk(includeUnconvertedUserCount: boolean = false) {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const getUsersOptions = usersPageTableOptionsSelector(getState());

    if (getUsersOptions) {
      dispatch(setEntitiesLoadingAction(true));

      let body = formatUserFilters(getUsersOptions);

      if (includeUnconvertedUserCount) {
        body = {
          ...body,
          includeUnconvertedUserCount,
        };
      }

      const result = await axiosApi(
        `${getAppSettings().ssoApiUrl}/Users/search`,
        {
          method: 'post',
          data: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).catch((error) => {
        dispatch(
          setBannerAction({
            message: 'Unable to load the user list. Please try again.',
            type: 'error',
            bannerAction: loadUsersThunk(includeUnconvertedUserCount),
          })
        );
        return Promise.reject(error);
      });

      dispatch(setEntitiesLoadingAction(false));

      dispatch(loadUsersSuccessAction(result));
      return result;
    }

    return Promise.reject();
  };
}

export function resetFiltersThunk() {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(resetFiltersAction());
    dispatch(searchUsersThunk());
  };
}

const searchUsersDebounce = debounce(
  (dispatch: Dispatch, state) =>
    setTimeout(() => {
      const getUsersOptions = usersPageTableOptionsSelector(state);
      dispatch(setEntitiesLoadingAction(true));

      if (getUsersOptions) {
        const body = formatUserFilters(getUsersOptions);

        axiosApi(`${getAppSettings().ssoApiUrl}/Users/search`, {
          method: 'post',
          data: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((payload) => dispatch(loadUsersSuccessAction(payload)))
          .then(() => dispatch(setEntitiesLoadingAction(false)))
          .catch((error) => {
            dispatch(
              setBannerAction({
                message: 'Unable to load the user list. Please try again.',
                type: 'error',
                bannerAction: searchUsersThunk(),
              })
            );
            return Promise.reject(error);
          });
      }
    }),
  300
);

export function searchUsersThunk() {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    searchUsersDebounce(dispatch, getState());
  };
}

export function setPageThunk(page: number) {
  return async (dispatch: Dispatch): Promise<void> => {
    await dispatch(setPageAction(page));
    dispatch(loadUsersThunk());
  };
}

export function setPageSizeThunk(pageSize: number) {
  return async (dispatch: Dispatch): Promise<void> => {
    await dispatch(setPageSizeAction(pageSize));
    dispatch(loadUsersThunk());
  };
}

export function setSearchTermThunk(term: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(setSearchTermAction(term));
    dispatch(setEntitiesLoadingAction(true));
    dispatch(searchUsersThunk());
  };
}

export function setSortOrderThunk(sortOrder: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    await dispatch(setSortOrderAction(sortOrder));
    dispatch(loadUsersThunk());
  };
}

function reloadUserOrRedirectThunk(id: string) {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const openReassignment = isOpenSelector(
      getState(),
      customerReassignmentConfirmDialogName
    );
    dispatch(loadUserThunk(id))
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          dispatch(clearBannerAction());
          if (!openReassignment) {
            window.location.replace('#/users');
          } else {
            dispatch(setWillRedirectAction(true));
          }
        }

        return Promise.reject(error);
      })
      .finally(() => {
        dispatch(setIsBusyAction(false));
      });
  };
}

function checkForCustomerReassignmentThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const crmProducts = getUserDealershipProducts(
      user,
      productToIntEnum('crm')
    );

    const sitesWithEvents = [];
    const promises = [];
    crmProducts.forEach((crmProduct) => {
      promises.push(
        getCrmHasUserEvents(
          crmProduct.identifier,
          crmProduct.productInfo.userName
        ).then((hasEvents) => {
          if (hasEvents) {
            sitesWithEvents.push({
              siteId: crmProduct.defaultDealershipId,
              username: crmProduct.userName,
            });
          }
        })
      );
    });

    Promise.all(promises)
      .then(() => {
        if (sitesWithEvents.length === 1) {
          dispatch(setReassignmentInfoAction(sitesWithEvents[0]));
          dispatch(openDialogAction(customerReassignmentConfirmDialogName));
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        dispatch(reloadUserOrRedirectThunk(user.id));
      });
  };
}
