// @flow
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import FormGroup from '@material-ui/core/FormGroup';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import { productEnum } from '../../utils/enums';
import { required } from '../../utils/validation';
import { defaultInputProps } from '../../constants';
import { Form } from '../form/form.component';

const styles = () => {
  return {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    passwordForm: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    textFieldIcon: {
      marginRight: 5,
    },
  };
};

type ForgotPasswordProps = {
  classes: any,
  isSubmitting: boolean,
  isUsernameFieldEmpty: boolean,
  onCancel: any,
  onSubmit: any,
  product: any,
  username: string,
};

const ForgotPasswordComponent = (props: ForgotPasswordProps) => {
  const {
    classes,
    isSubmitting,
    isUsernameFieldEmpty,
    onCancel,
    onSubmit,
    product,
    username,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(change('pageForm', 'username', username));
  }, []);

  return (
    <>
      <Typography paragraph>
        Enter your {productEnum(product.type)} username and we&apos;ll send an
        account recovery code to the email associated with that account.
      </Typography>
      <div className={classes.container}>
        <Form className={classes.passwordForm} onSubmit={onSubmit}>
          <FormGroup>
            <FormTextField
              autoComplete="username"
              autoFocus
              data-e2e="usernameField"
              disabled={isSubmitting}
              inputProps={defaultInputProps}
              label={`${productEnum(product.type)} username`}
              name="username"
              required
              startAdornment={<PersonIcon className={classes.textFieldIcon} />}
              validate={[required]}
            />
          </FormGroup>
          <div className={classes.buttonContainer}>
            <Button data-e2e="cancelButton" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              data-e2e="sendEmailButton"
              disabled={isUsernameFieldEmpty || isSubmitting}
              type="submit"
            >
              Send Email
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const username = selector(state, 'username');
  return {
    isUsernameFieldEmpty:
      username === null || username === undefined || username.trim() === '',
  };
}

export const StyledComponent = withStyles(styles)(ForgotPasswordComponent);

export const ForgotPassword = connect(mapStateToProps)(StyledComponent);
