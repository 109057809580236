// @flow
import React from 'react';
import type { Element } from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';

export const styles = (theme: any) => {
  return {
    leftNavLink: {
      textDecoration: 'none !important',
    },

    leftNavLinkActive: {
      '& > li': {
        backgroundColor: theme.palette.primary.dark,
      },
    },

    leftNavMenuItem: {
      maxHeight: 44,
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&>h6': {
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 1,
        color: theme.palette.common.white,
      },
      '& svg': {
        flexShrink: 0,
        marginRight: '14px !important',
        left: '0 !important',
        fill: `${theme.palette.common.white} !important`,
        fontSize: 24,
      },
    },

    leftNavFooterMenuItem: {
      maxHeight: 44,
      backgroundColor: theme.colors.themeA900,
      color: `${theme.palette.common.white} !important`,
      '&:hover': {
        backgroundColor: theme.colors.themeA900,
      },
      '&:focus': {
        backgroundColor: theme.colors.themeA900,
      },
      '&>h6': {
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 1,
        color: theme.palette.common.white,
      },
      '& svg': {
        flexShrink: 0,
        marginRight: '14px !important',
        left: '0 !important',
        fill: `${theme.palette.common.white} !important`,
        fontSize: 24,
      },
    },
  };
};

type LeftNavItemProps = {
  defaultMessage: string,
  icon: Element<*>,
  isActive?: () => void,
  path: string,
};

type InternalProps = {
  classes: JssClasses,
};

const LeftNavItemComponent = (props: LeftNavItemProps & InternalProps) => {
  const { classes, path, icon, isActive, defaultMessage } = props;
  return (
    <NavLink
      to={`/${path}`}
      className={classes.leftNavLink}
      activeClassName={classes.leftNavLinkActive}
      isActive={isActive || null}
      replace
    >
      <MenuItem className={classes.leftNavMenuItem} value={path}>
        {icon}
        <Typography variant={TypographyVariants.Subtitle1}>
          {defaultMessage}
        </Typography>
      </MenuItem>
    </NavLink>
  );
};

export const LeftNavItem = withStyles(styles)(LeftNavItemComponent);
