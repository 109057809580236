// @flow
import { isMock } from '@dealersocket/react-common';
import { connect } from 'react-redux';
import { PrivateRoute } from './private-route';

function mapStateToProps(state) {
  const { user } = state.oidc;
  return {
    isAuthorized:
      isMock ||
      !user ||
      (user.profile &&
        user.profile.role &&
        user.profile.role.includes('sso_internal')),
  };
}

const mapDispatchToProps = {};

export const ConnectedPrivateInternalRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
