// @flow
import React from 'react';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CardHeader from '@material-ui/core/CardHeader';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  DealerFireLogoIcon,
  AutoMateLogoIcon,
  InvPlusIcon,
  CrmLogoIcon,
  IdmsLogoIcon,
} from '@dealersocket/ds-ui-react/logos';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { productEnum } from 'shared/utils/enums';

const styles = (theme) => {
  return {
    arrow: {
      height: 10,
    },
    avatar: { width: '100%' },
    avatarContent: { display: 'flex' },
    card: {
      height: 71,
      width: '100%',
    },
    cardHeaderContainer: {
      '&.MuiCardHeader-avatar': {
        width: '100%',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    expandedCard: {
      height: 'auto',
    },
    expandedContentContainer: {
      marginLeft: 61,
      marginRight: 58,
      marginTop: 16,
      [theme.breakpoints.only('xs')]: {
        margin: 0,
      },
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    logo: {
      height: 41,
      marginLeft: 5,
      marginRight: 15,
      '& svg': {
        display: 'block',
      },
    },
    productTitle: { marginBottom: 'auto', marginTop: 'auto' },
  };
};

type InternalProps = {
  classes: any,
};

type ProductCardExternalProps = {
  content?: any,
  expanded?: boolean,
  onClick: any,
  product: any,
  readOnly?: boolean,
};

export type ProductCardProps = InternalProps & ProductCardExternalProps;
const sharedLogoProps = { height: '41', width: '41' };
const getProductLogo = (product: any) => {
  switch (product.type) {
    case 1:
      return <CrmLogoIcon {...sharedLogoProps} />;
    case 2:
      return <InvPlusIcon {...sharedLogoProps} />;
    case 3:
      return <DealerFireLogoIcon {...sharedLogoProps} />;
    case 4:
      return <AutoMateLogoIcon {...sharedLogoProps} />;
    case 5:
      return <IdmsLogoIcon {...sharedLogoProps} />;
    default:
      return null;
  }
};

const ProductCardCore = (props: ProductCardProps) => {
  const { classes, product, readOnly, expanded, content, onClick } = props;
  const accountLinked = product.linked;

  const renderExpandableCard = () => {
    return (
      <Card
        square
        className={classNames({
          [classes.expandedCard]: expanded,
          [classes.card]: !expanded,
        })}
      >
        <div className={classes.cardHeaderContainer}>
          <CardHeader
            classes={{ avatar: classes.avatar }}
            onClick={onClick(product.type)}
            avatar={
              <div className={classes.headerContainer}>
                <div className={classes.avatarContent}>
                  <div className={classes.logo}>{getProductLogo(product)}</div>
                  <div className={classes.productTitle}>
                    <Typography variant={TypographyVariants.H4}>
                      {productEnum(product.type)}
                    </Typography>
                  </div>
                </div>

                <div>
                  {expanded ? (
                    <ArrowDropUpIcon color="primary" fontSize="large" />
                  ) : (
                    <ArrowDropDownIcon color="primary" fontSize="large" />
                  )}
                </div>
              </div>
            }
          />
        </div>

        <CardContent>
          {expanded && (
            <div className={classes.expandedContentContainer}>{content}</div>
          )}
        </CardContent>
      </Card>
    );
  };

  const renderLinkAccountCard = () => {
    return (
      <Card
        square
        className={classNames({
          [classes.expandedCard]: expanded,
          [classes.card]: !expanded,
        })}
      >
        <CardContent>
          <div className={classes.header}>
            <div className={classes.headerContainer}>
              <div className={classes.logo}>{getProductLogo(product)}</div>
              <div className={classes.productTitle}>
                <Typography variant={TypographyVariants.H4}>
                  {productEnum(product.type)}
                </Typography>
              </div>
            </div>
            {!readOnly && !expanded && (
              <Button color="primary" onClick={onClick(product.type)}>
                Link Account
              </Button>
            )}
          </div>
          {expanded && (
            <div className={classes.expandedContentContainer}>{content}</div>
          )}
        </CardContent>
      </Card>
    );
  };
  return accountLinked ? renderExpandableCard() : renderLinkAccountCard();
};

export const ProductCard = withStyles(styles)(ProductCardCore);
