// @flow
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import FlipIcon from '@material-ui/icons/Flip';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import { getCroppedImg } from 'shared/utils/get-cropped-image';

const styles = (theme) => {
  return {
    cropContainer: {
      background: theme.palette.common.black,
      height: '100%',
      padding: 20,
      position: 'relative',
    },
    cropGroup: {
      height: 'calc(25vh)',
      maxHeight: 250,
      maxWidth: 400,
      padding: '0px 10px 10px 10px',
      width: 'calc(19vw)',
      [theme.breakpoints.down('md')]: {
        height: 'calc(50vh)',
        maxHeight: 'none',
        maxWidth: 493,
        padding: '0px 10px 10px 10px',
        width: 'calc(90vw)',
      },
    },
    flipButton: {
      minWidth: 20,
      padding: 5,
    },
    flipGroup: {
      display: 'flex',
      paddingLeft: 5,
      paddingTop: 10,
    },
    flipHorizontalIcon: {
      fontSize: 20,
    },
    flipVerticalIcon: {
      fontSize: 20,
      transform: 'rotate(90deg)',
    },
    sliderGroup: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      padding: '0px 10px 0px 10px',
    },
    sliderText: {
      width: 75,
    },
    uploadButton: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 20,
      paddingTop: 5,
    },
  };
};

type CropperComponentProps = {
  onCancel: any,
  setCroppedImage: any,
  urlImage: any,
};

type InternalProps = {
  classes: any,
};

const CropperComponent = (props: CropperComponentProps & InternalProps) => {
  const { classes, setCroppedImage, onCancel, urlImage } = props;

  const [croppedImageAreaPixels, setCroppedImageAreaPixels] = useState(null);
  const [disableUpload, setDisableUpload] = useState(false);
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);
  const [imageCrop, setImageCrop] = useState({ x: 0, y: 0 });
  const [imageRotation, setImageRotation] = useState(0);
  const [imageZoom, setImageZoom] = useState(1);

  const uploadCroppedImage = useCallback(async () => {
    setDisableUpload(true);
    const croppedImageResult = await getCroppedImg(
      urlImage,
      croppedImageAreaPixels,
      imageRotation,
      flipHorizontal,
      flipVertical
    );

    setCroppedImage(croppedImageResult);
  }, [croppedImageAreaPixels, imageRotation, flipHorizontal, flipVertical]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedImageAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <div>
      <div className={classes.cropGroup}>
        <div className={classes.cropContainer}>
          <Cropper
            aspect={1}
            crop={imageCrop}
            cropShape="round"
            image={urlImage}
            onCropChange={setImageCrop}
            onCropComplete={onCropComplete}
            onRotationChange={setImageRotation}
            onZoomChange={setImageZoom}
            rotation={imageRotation}
            showGrid={false}
            transform={[
              `translate(${imageCrop.x}px, ${imageCrop.y}px)`,
              `rotateZ(${imageRotation}deg)`,
              `rotateY(${flipHorizontal ? 180 : 0}deg)`,
              `rotateX(${flipVertical ? 180 : 0}deg)`,
              `scale(${imageZoom})`,
            ].join(' ')}
            zoom={imageZoom}
            zoomSpeed={0.1}
          />
        </div>
      </div>
      <div className={classes.sliderGroup}>
        <Typography className={classes.sliderText}>Zoom</Typography>
        <Slider
          color="secondary"
          data-e2e="zoomSlider"
          max={3}
          min={1}
          onChange={(e, zoom) => setImageZoom(zoom)}
          step={0.01}
          value={imageZoom}
          valueLabelDisplay="auto"
        />
      </div>
      <div className={classes.sliderGroup}>
        <Typography className={classes.sliderText}>Rotation</Typography>
        <Slider
          color="secondary"
          data-e2e="rotationSlider"
          max={180}
          min={0}
          onChange={(e, rotation) => setImageRotation(rotation)}
          step={1}
          value={imageRotation}
          valueLabelDisplay="auto"
        />
      </div>

      <div className={classes.flipGroup}>
        <Tooltip title="Flip Horizontal">
          <div>
            <Button
              className={classes.flipButton}
              data-e2e="flipHorizontal"
              onClick={() => setFlipHorizontal(!flipHorizontal)}
            >
              <FlipIcon
                className={classes.flipHorizontalIcon}
                color="primary"
              />
            </Button>
          </div>
        </Tooltip>
        <Tooltip title="Flip Vertical">
          <div>
            <Button
              className={classes.flipButton}
              data-e2e="flipVertical"
              onClick={() => setFlipVertical(!flipVertical)}
            >
              <FlipIcon className={classes.flipVerticalIcon} color="primary" />
            </Button>
          </div>
        </Tooltip>
      </div>

      <div className={classes.uploadButton}>
        <Button
          color="primary"
          data-e2e="uploadPhotoButton"
          onClick={uploadCroppedImage}
          disabled={disableUpload}
        >
          Upload Photo
        </Button>
        <Button data-e2e="cancelButton" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export const CustomCropper = withStyles(styles)(CropperComponent);
