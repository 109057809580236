// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { DsLogoProgress } from '@dealersocket/ds-ui-react/DsLogoProgress';
import { PersonalInfoView } from './personal-info-view/personal-info-view.component';

const styles = (theme) => {
  return {
    loaderStyle: {
      paddingTop: 'calc(50vh)',
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: 'calc(50vh - 96px)',
      },
    },
    root: {
      flexGrow: 1,
    },
  };
};

export type PersonalInfoPageProps = {
  user: any,
};

type InternalProps = {
  classes: JssClasses,
};

const PersonalInfoPageComponent = (
  props: PersonalInfoPageProps & InternalProps
) => {
  const { classes, user } = props;

  return (
    <>
      {user ? (
        <div className={classes.root}>
          <PersonalInfoView selectedUser={user} />
        </div>
      ) : (
        <div className={classes.loaderStyle}>
          <DsLogoProgress size="large" data-e2e="loadingLogo" />
        </div>
      )}
    </>
  );
};

export const PersonalInfoPage = withStyles(styles)(PersonalInfoPageComponent);
