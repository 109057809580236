// @flow
import { axiosResult, getAppSettings } from '@dealersocket/react-common';
import globToRe from 'glob-to-regexp';

export function mockApi(axiosMock: any): any {
  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/Scopes*`))
    .reply(() => {
      const scopes = [];

      for (let index = 1; index <= 50; index += 1) {
        scopes.push({
          name: `mockscope${index}`,
          displayName:
            index % 5
              ? `Mock Scope ${index < 10 ? '0' : ''}${index}`
              : `Super Long Name Mock Scope ${index < 10 ? '0' : ''}${index}`,
          description: `Description ${index}`,
          enabled: index % 7 === 1,
          type: index % 2,
          created: new Date(new Date().setDate(new Date().getDate() - 5)),
          lastModified: Date.now(),
          claims:
            index % 6 === 0
              ? []
              : [
                  {
                    name: 'claim1',
                    description: 'Claim 1',
                  },
                  {
                    name: 'claim2',
                    description: 'Claim 2',
                  },
                ],
          scopeSecrets: [
            {
              description: `firstTestSecret${index}`,
              value: `firsthashValue${index}`,
            },
            {
              description: `secondTestSecret${index}`,
              value: `secondhashValue${index}`,
            },
            {
              description: `thirdTestSecret${index}`,
              value: `thirdhashValue${index}`,
            },
          ],
        });
      }
      return axiosResult(scopes);
    });

  axiosMock
    .onPost(globToRe(`${getAppSettings().ssoApiUrl}/Scopes*`))
    .reply((config) => {
      return axiosResult(config.data);
    });

  axiosMock
    .onPut(globToRe(`${getAppSettings().ssoApiUrl}/Scopes*`))
    .reply((config) => {
      return axiosResult(config.data);
    });

  axiosMock
    .onDelete(globToRe(`${getAppSettings().ssoApiUrl}/Scopes*`))
    .reply(() => {
      return axiosResult('');
    });
}
