// @flow
import globToRe from 'glob-to-regexp';
import { axiosResult, getAppSettings } from '@dealersocket/react-common';

export function mockApi(axiosMock: any): any {
  const dealerships = [];

  dealerships.push({
    name: 'Ken Garff Ford',
    id: '1ba54488-2830-459f-b7a2-016264b2bbb8',
  });

  dealerships.push({
    name: 'Ken Garff Honda',
    id: 'f11a5ad7-7819-44e1-b331-1fd07f6d786b',
  });

  dealerships.push({
    name: 'Ken Garff Volvo',
    id: '0c2aa780-3642-43ad-bd8b-76fa56315b58',
  });

  dealerships.push({
    name: 'Land Rover Centerville',
    id: 'e99312dc-df57-45da-988c-fabcba1794c7',
  });

  dealerships.push({
    name: 'Long Beach Toyota',
    id: 'b36ca799-c51c-45c2-b326-60201b868edd',
  });

  dealerships.push({
    name: 'Maserati and Alfa Romeo of Salt Lake',
    id: 'ca6998f5-e63a-46a2-998d-656dfad98fa9',
  });

  dealerships.push({
    name: 'Mercedes-Benz of Salt Lake City',
    id: '58c825b4-6a69-4ec0-bd8b-e91540ec4310',
  });

  dealerships.push({
    name: 'Stadium Nissan',
    id: '2cad1092-a11a-4fe1-85ca-42512cda4d33',
  });

  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/Dealership*`))
    .reply(() => {
      return axiosResult(dealerships);
    });
}
