// @flow
import { axiosResult, getAppSettings } from '@dealersocket/react-common';
import globToRe from 'glob-to-regexp';

export function mockApi(axiosMock: any): any {
  let lastUpdated = null;
  let lastDeleted = null;

  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/Clients*`))
    .reply(() => {
      let clients = [];

      for (let index = 1; index <= 50; index += 1) {
        clients.push({
          clientUri: `http://www.dealersocket.com/${index}`,
          clientId: `mockClient${index}`,
          clientName:
            index % 5
              ? `Mock Client ${index < 10 ? '0' : ''}${index}`
              : `Super Long Name Mock Client ${index < 10 ? '0' : ''}${index}`,
          enabled: index % 2 === 0,
          flow: 6 - (index % 7),
          accessTokenLifetime:
            index % 3 === 0 ? 7200 : 7200 * (10 - (index % 10)),
          requireConsent: index % 3 === 0,
          logoutSessionRequired: index % 2 === 0,
          logoUri:
            index % 4 === 0
              ? `https://cdn-nightly.dealersocket.engineering/web.styles/0.1.196/app/assets/img/ds-logo-small${index}.png`
              : null,
          product: index % 2 === 0 ? 1 : 2,
          logoutUri:
            index % 3 === 0
              ? `https://local.dealersocket.com/CRM/Logout.aspx`
              : null,
          created: new Date(new Date().setDate(new Date().getDate() - 5)),
          lastModified: Date.now(),
          refreshTokenUsage: 2 - (index % 3),
          refreshTokenExpiration: 1 - (index % 2),
          absoluteRefreshTokenLifetime: 2592000,
          slidingRefreshTokenLifetime: 1296000,
          clientSecrets: [
            {
              description: `firstTestSecret${index}`,
              value: `firsthashValue${index}`,
            },
            {
              description: `secondTestSecret${index}`,
              value: `secondhashValue${index}`,
            },
            {
              description: `thirdTestSecret${index}`,
              value: `thirdhashValue${index}`,
            },
          ],
          redirectUris: [
            `http://localhost:8082/web.loader/${index}`,
            `http://localhost:8082/web.loader/silent-renew${index}.html`,
            `http://localhost:8082/web.shell/${index}`,
            `http://localhost:8082/web.shell/silent-renew${index}.html`,
            `https://blackbird.local.dealersocket.com/web.loader${index}`,
            `https://blackbird.local.dealersocket.com/web.shell${index}`,
          ],
          postLogoutRedirectUris: [
            `http://localhost:8082/web.loader${index}`,
            `http://localhost:8082/web.loader/silent-renew.html${index}`,
            `http://localhost:8082/web.shell`,
            `http://localhost:8082/web.shell/silent-renew.html${index}`,
            `https://blackbird.local.dealersocket.com/web.loader${index}`,
            `https://blackbird.local.dealersocket.com/web.shell${index}`,
          ],
          allowedScopes:
            index % 6 === 0
              ? []
              : [
                  'mockscope1',
                  'mockscope34',
                  'mockscope2',
                  'mockscope4',
                  'mockscope14',
                ],
        });
      }
      if (lastUpdated) {
        const existingElement = clients
          .map((client) => {
            return client.clientName;
          })
          .indexOf(lastUpdated.clientName);
        if (existingElement >= 0) {
          clients.splice(existingElement, 1, lastUpdated);
        }
      }

      if (lastDeleted) {
        clients = clients.filter((client) => {
          return client.clientId !== lastDeleted;
        });
      }

      return axiosResult(clients);
    });

  axiosMock
    .onPost(globToRe(`${getAppSettings().ssoApiUrl}/Clients*`))
    .reply((config) => {
      return axiosResult(config.data);
    });

  axiosMock
    .onPut(globToRe(`${getAppSettings().ssoApiUrl}/Clients*`))
    .reply((config) => {
      lastUpdated = JSON.parse(config.data);
      return axiosResult(config.data);
    });

  axiosMock
    .onDelete(globToRe(`${getAppSettings().ssoApiUrl}/Clients*`))
    .reply((config) => {
      lastDeleted = config.url.substr(config.url.lastIndexOf('/') + 1);
      return axiosResult('');
    });
}
