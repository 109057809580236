// @flow
export const ssoRoleLevel = {
  sso_internal: 5,
  sso_data_admin: 4,
  sso_support: 3,
  sso_dealer_group: 2,
  sso_dealership: 1,
  '': 0,
};

export const isInAdminRole = (ssoRole: string): boolean =>
  ssoRole === 'sso_internal' ||
  ssoRole === 'sso_support' ||
  ssoRole === 'sso_data_admin' ||
  ssoRole === 'sso_dealer_group' ||
  ssoRole === 'sso_dealership';

export const getAvailableRoles = (ssoRole: string): [] => {
  const availableRoles = [];

  switch (ssoRole) {
    case 'sso_internal':
      availableRoles.push({
        role: 'sso_internal',
        description: 'Engineering user admin',
      });
    // fall through
    case 'sso_data_admin':
      availableRoles.push({
        role: 'sso_data_admin',
        description: 'Data login Admin',
      });
    // fall through
    case 'sso_support':
      availableRoles.push({
        role: 'sso_support',
        description: 'DealerSocket support user admin',
      });
    // fall through
    case 'sso_dealer_group':
      availableRoles.push({
        role: 'sso_dealer_group',
        description: 'Dealer group user admin',
      });
    // fall through
    case 'sso_dealership':
      availableRoles.push({
        role: 'sso_dealership',
        description: 'Dealership user admin',
      });
    // fall through
    case '':
      availableRoles.push({ role: '', description: 'Login user' });
    // fall through
    default:
      break;
  }
  return availableRoles;
};
