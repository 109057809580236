// @flow
import { createSelector } from 'reselect';
import { formattedUser } from '../../../shared/utils/format-user';

const selectSlice = (state: any) => state.users;

export const usersSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.users;
  }
);

export const totalUsersSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.totalUsers;
  }
);

export const usersLoadedSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.usersLoaded;
  }
);

export const selectedUserSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.selectedUser;
  }
);

export const selectedUserFormattedSelector = createSelector(
  selectedUserSelector,
  (selectedUser) => formattedUser(selectedUser)
);

export const noFilterAppliedSelector = createSelector(
  selectSlice,
  (slice) => {
    return (
      slice.usersPageTableOptions.appliedDealerships.length === 0 &&
      slice.usersPageTableOptions.appliedDepartments.length === 0 &&
      slice.usersPageTableOptions.appliedLoginRoles.length === 0 &&
      slice.usersPageTableOptions.appliedStatus === 'Active'
    );
  }
);

export const expandedFilterSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.expandedFilter;
  }
);

export const isBusySelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.isBusy;
  }
);

export const usersPageTableOptionsSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.usersPageTableOptions;
  }
);

export const usersReportPageTableOptionsSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.usersReportPageTableOptions;
  }
);

export const willRedirectSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.willRedirect;
  }
);
