// @flow
import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import {
  accountRoute,
  accountDealershipsRoute,
  accountAccessRoute,
} from 'shared/constants';
import { formattedEditInfoUser } from 'shared/utils/format-user';
import { getUserRole } from 'shared/utils/get-user-role';
import {
  a11yProps,
  LinkTab,
} from 'shared/components/link-tab/link-tab.component';
import { selectedUserSelector } from '../../../users/state/users.selectors';
import { AccountSettingsProfile } from './account-settings-profile.component';

const styles = (theme) => {
  return {
    appBarColors: {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        marginTop: 44,
        position: 'fixed',
      },
    },
    indicator: {
      top: 'initial',
    },
    paper: {
      border: 'none',
      boxShadow: 'none',
      [theme.breakpoints.up('lg')]: {
        maxWidth: 480,
        minWidth: 250,
        overflow: 'hidden auto',
        overflowY: 'auto',
        width: 'calc(30vw)',
      },
    },
  };
};

type InternalProps = {
  classes: JssClasses,
};

type AccountSettingsNavProps = {
  index: number,
  setIndex: () => void,
};

const AccountSettingsNavComponent = (
  props: AccountSettingsNavProps & InternalProps
) => {
  const { classes, index, setIndex } = props;

  const history = useHistory();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event, newValue) => {
    switch (event.target.innerText.toLowerCase().trim()) {
      case 'account':
        history.push(accountRoute);
        break;
      case 'dealership':
        history.push(accountDealershipsRoute);
        break;
      case 'dealersocket access':
        history.push(accountAccessRoute);
        break;
      default:
        break;
    }
    setIndex(newValue);
  };

  const selectedUser = formattedEditInfoUser(
    useSelector((state) => selectedUserSelector(state))
  );

  const oidcUser = useSelector((state) => state.oidc.user);
  const role = getUserRole(oidcUser);
  const isInternalAdmin = role === 'sso_internal';

  let displayedTabs = [
    { name: 'Account', url: '/#/account/settings/personalinfo' },
    { name: 'Dealership', url: '/#/account/settings/dealerships' },
    { name: 'DealerSocket Access', url: '/#/account/settings/access' },
  ];

  if (!isInternalAdmin) {
    displayedTabs = displayedTabs.filter((tab) => tab.name !== 'Dealership');
  }

  return (
    <Paper square className={classes.paper}>
      {!md && <AccountSettingsProfile user={selectedUser} />}
      <AppBar
        classes={{ colorDefault: classes.appBarColors }}
        color="default"
        position="sticky"
      >
        <Tabs
          orientation={md ? 'horizontal' : 'vertical'}
          variant={md ? 'scrollable' : 'fullWidth'}
          scrollButtons="auto"
          value={index}
          classes={{
            indicator: classes.indicator,
          }}
          onChange={handleChange}
        >
          {displayedTabs.map((tab, tabIndex) => {
            return (
              <LinkTab
                key={tab.name}
                label={tab.name}
                href={tab.url}
                {...a11yProps(tabIndex)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </Paper>
  );
};

export const AccountSettingsNav = withStyles(styles)(
  AccountSettingsNavComponent
);
