// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  APPLY_FILTERS,
  CLEAR_UNAPPLIED_FILTER,
  SET_FILTER,
  SET_EXPANDED_FILTER,
  LOAD_USERS_SUCCESS,
  SET_IS_BUSY,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  RESET_FILTERS,
  sliceName,
} from './reports.types';

export const initialState = {
  users: [],
  usersLoaded: false,
  totalUsers: 0,
  selectedUser: null,
  expandedFilter: 'Dealerships',
  usersReportPageTableOptions: {
    page: 0,
    limit: 25,
    orderBy: 8,
    filterTerm: '',
    sortOrder: 'asc',
    sortTerm: 'emailUpdateDaysLeft',
    selectedDealerships: [],
    appliedDealerships: [],
  },
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case APPLY_FILTERS:
      return {
        ...state,
        usersReportPageTableOptions: {
          ...state.usersReportPageTableOptions,
          appliedDealerships: [
            ...state.usersReportPageTableOptions.selectedDealerships,
          ],
        },
      };
    case CLEAR_UNAPPLIED_FILTER:
      return {
        ...state,
        usersReportPageTableOptions: {
          ...state.usersReportPageTableOptions,
          selectedDealerships:
            state.usersReportPageTableOptions.appliedDealerships,
        },
      };
    case SET_EXPANDED_FILTER:
      return { ...state, expandedFilter: action.payload };
    case SET_FILTER: {
      const { filter, value } = action.payload;
      if (filter) {
        return {
          ...state,
          usersReportPageTableOptions: {
            ...state.usersReportPageTableOptions,
            [`selected${filter}`]: value,
          },
        };
      }
      return { ...state };
    }
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        usersLoaded: true,
        users: action.payload.users,
        totalUsers: action.payload.totalUsers,
      };
    case SET_IS_BUSY: {
      return {
        ...state,
        isBusy: action.payload,
      };
    }
    case SET_PAGE:
      if (action.payload !== state.usersReportPageTableOptions.page) {
        return {
          ...state,
          usersReportPageTableOptions: {
            ...state.usersReportPageTableOptions,
            page: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    case SET_PAGE_SIZE:
      if (action.payload !== state.usersReportPageTableOptions.limit) {
        return {
          ...state,
          usersReportPageTableOptions: {
            ...state.usersReportPageTableOptions,
            limit: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SEARCH_TERM:
      if (action.payload !== state.usersReportPageTableOptions.filterTerm) {
        return {
          ...state,
          usersReportPageTableOptions: {
            ...state.usersReportPageTableOptions,
            filterTerm: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SORT_ORDER: {
      let orderBy;
      if (state.usersReportPageTableOptions) {
        ({ orderBy } = state.usersReportPageTableOptions);
      }
      const { sortOrder, sortTerm } = action.payload;
      switch (sortTerm) {
        case 'name':
          orderBy = sortOrder === 'asc' ? 0 : 1;
          break;
        case 'userName':
          orderBy = sortOrder === 'asc' ? 2 : 3;
          break;
        case 'emailUpdateDaysLeft':
          orderBy = sortOrder === 'asc' ? 8 : 9;
          break;
        default:
          orderBy = undefined;
      }
      if (state.usersReportPageTableOptions.orderBy !== orderBy) {
        return {
          ...state,
          usersReportPageTableOptions: {
            ...state.usersReportPageTableOptions,
            orderBy,
            sortOrder,
            sortTerm,
          },
        };
      }
      return {
        ...state,
      };
    }
    case RESET_FILTERS:
      return {
        ...state,
        usersReportPageTableOptions: {
          ...state.usersReportPageTableOptions,
          selectedDealerships:
            initialState.usersReportPageTableOptions.selectedDealerships,
          appliedDealerships:
            initialState.usersReportPageTableOptions.appliedDealerships,
        },
      };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
