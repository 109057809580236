// @flow
import { Dispatch } from 'redux';
import { axiosApi, getAppSettings } from '@dealersocket/react-common';
import { departmentsSelector } from './departments.selectors';
import {
  loadDepartmentsAction,
  loadDepartmentsErrorAction,
  loadDepartmentsSuccessAction,
} from './department.actions';

export function loadDepartmentsThunk() {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const state = getState();
    const departments = departmentsSelector(state);
    if (departments && departments.length) {
      return;
    }

    dispatch(loadDepartmentsAction());

    try {
      const result = await axiosApi(
        `${getAppSettings().ssoApiUrl}/Departments`
      );
      dispatch(loadDepartmentsSuccessAction(result));
    } catch (error) {
      dispatch(loadDepartmentsErrorAction(error.message));
    }
  };
}
