// @flow
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountBox from '@material-ui/icons/AccountBox';
import Build from '@material-ui/icons/Build';
import DeviceHub from '@material-ui/icons/DeviceHub';
import Storage from '@material-ui/icons/Storage';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { FlexSpacer } from '@dealersocket/ds-ui-react/FlexSpacer';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { getUserRole } from '../../utils/get-user-role';
import { LeftNavFooter } from '../left-nav-footer/left-nav-footer';
import { LeftNavItem } from '../left-nav-item/left-nav-item';
import {
  setLeftNavExpandedAction,
  toggleLeftNavOpenAction,
} from './state/left-nav.actions';
import {
  isLeftNavChangedByUserSelector,
  isLeftNavExpandedSelector,
  isLeftNavOpenSelector,
} from './state/left-nav.selectors';
import { isExternalLoginUser } from '../../utils/is-external-login-user';

const drawerWidth = 230;
const collapsedDrawerWidth = 52;
const styles = (theme) => {
  return {
    drawer: {
      flexShrink: 0,
      width: drawerWidth,
    },
    collapsedDrawer: {
      flexShrink: 0,
      width: collapsedDrawerWidth,
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidth,
    },
    collapsedDrawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: collapsedDrawerWidth,
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    button: {
      color: theme.palette.common.white,
      paddingRight: 15,
    },
  };
};

export type LeftNavProps = {
  leftNavChangedByUser: boolean,
  leftNavExpanded: boolean,
  leftNavOpen: boolean,
  setLeftNavExpandedAction: (expanded: boolean) => void,
  toggleLeftNavOpenAction: () => void,
};

type InternalProps = {
  classes: JssClasses,
};

const checkActiveAcctSettings = (match, location) => {
  if (!location) return false;
  const { pathname } = location;
  return pathname.includes('account/settings');
};

const navItems = (xs: boolean, role: string) => (
  <>
    {(role === 'sso_internal' ||
      role === 'sso_support' ||
      role === 'sso_data_admin' ||
      role === 'sso_dealer_group' ||
      role === 'sso_dealership') && (
      <LeftNavItem
        path="users"
        icon={<AccountBox />}
        defaultMessage="Account Management"
      />
    )}
    {role === 'sso_internal' && (
      <LeftNavItem path="clients" icon={<Storage />} defaultMessage="Clients" />
    )}
    {role === 'sso_internal' && (
      <LeftNavItem path="scopes" icon={<DeviceHub />} defaultMessage="Scopes" />
    )}
    {role === 'sso_internal' && (
      <LeftNavItem
        path="loginProviders"
        icon={<VerifiedUser />}
        defaultMessage="Login providers"
      />
    )}
    {xs ? (
      <LeftNavItem
        path="account/settings"
        icon={<Build />}
        isActive={checkActiveAcctSettings}
        defaultMessage="Account Settings"
      />
    ) : (
      <LeftNavItem
        path="account/settings/personalinfo"
        icon={<Build />}
        isActive={checkActiveAcctSettings}
        defaultMessage="Account Settings"
      />
    )}
  </>
);

const LeftNavHolderComponent = (props: LeftNavProps & InternalProps) => {
  const { leftNavChangedByUser, leftNavExpanded, classes } = props;

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  if (!leftNavChangedByUser) {
    props.setLeftNavExpandedAction(lgUp);
  }

  const user = useSelector((state) => state.oidc.user);
  const role = getUserRole(user);
  const isExternal = isExternalLoginUser(user);

  const isAdmin =
    role === 'sso_internal' ||
    role === 'sso_support' ||
    role === 'sso_data_admin';

  if (role === '' || (isExternal && !isAdmin)) {
    return null;
  }

  const content = navItems(xs, role);

  return xs ? (
    <Drawer
      open={props.leftNavOpen}
      onClose={props.toggleLeftNavOpenAction}
      variant="temporary"
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.toolbar} />
      {content}
    </Drawer>
  ) : (
    <Drawer
      variant="permanent"
      className={leftNavExpanded ? classes.drawer : classes.collapsedDrawer}
      classes={{
        paper: leftNavExpanded
          ? classes.drawerPaper
          : classes.collapsedDrawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      {content}
      <FlexSpacer />
      <LeftNavFooter expanded={leftNavExpanded} />
    </Drawer>
  );
};

const LeftNavHolder = withStyles(styles)(LeftNavHolderComponent);

function mapStateToProps(state) {
  return {
    leftNavOpen: isLeftNavOpenSelector(state),
    leftNavChangedByUser: isLeftNavChangedByUserSelector(state),
    leftNavExpanded: isLeftNavExpandedSelector(state),
  };
}

const mapDispatchToProps = {
  setLeftNavExpandedAction,
  toggleLeftNavOpenAction,
};

export const LeftNav = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeftNavHolder)
);
