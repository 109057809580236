// @flow
export const fsUser = {
  id: '80613f4b-b159-4cbe-be68-e1f1cfef133c',
  name: 'Justin Dennison',
  lastName: 'Dennison',
  firstName: 'Justin',
  userName: 'fsjdenniso',
  enabled: true,
  email: 'jdennison@dealersocket.com',
  emailConfirmed: true,
  phoneNumber: null,
  phoneNumberConfirmed: false,
  defaultEnterpriseDealership: {
    name: 'DealerSocket',
    id: '0ba54488-2830-459f-b7a2-016264b2bbb8',
  },
  products: [
    {
      id: '9265097',
      userName: 'fsjdenniso',
      enabled: true,
      productName: 'CRM',
      dealerships: [],
      roles: ['admin'],
      defaultDealershipId: '3',
      type: 1,
    },
  ],
  created: '2020-04-27T17:33:19.318786+00:00',
  lastModified: '2020-05-08T18:40:21.699651+00:00',
  roles: ['sso_internal', 'imp'],
  eulaAccepted: true,
  lastPasswordChange: '2020-05-18T14:14:02.23598+00:00',
  lockedOut: false,
  lockoutEnd: null,
  lockoutEnabled: true,
  accessFailedCount: 0,
  excludePasswordChange: false,
  invalidPassword: false,
  userStatus: 4,
  userStatusDisplay: 'Pending Email Verification',
};
