// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DataSelectField } from '@dealersocket/ds-ui-react/fields/DataSelectField';
import { requiredId } from 'shared/utils/validation';
import { loadDepartmentsThunk } from '../state/department.thunks';

type DepartmentEditExternalProps = {
  disabled: boolean,
};

const useStyles = makeStyles(() =>
  createStyles({
    selectField: {
      width: '100%',
    },
  })
);

export const DepartmentEdit = (props: DepartmentEditExternalProps) => {
  const { disabled } = props;

  const classes = useStyles();

  const formatDepartmentForSelect = (department) => ({
    value: department.id,
    label: department.name,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadDepartmentsThunk());
    return () => {};
  }, []);

  const { departments, isLoading, isError } = useSelector(
    (state) => state.departments
  );

  const defaultValue = { value: '', label: '- Select -' };
  let data = [defaultValue];
  data = data.concat(departments && departments.map(formatDepartmentForSelect));

  const renderComboBoxField = (field) => {
    const [value, setValue] = useState(field.input.value.id);
    useEffect(() => setValue(field.input.value.id), [field]);

    const getHelperText = () => {
      if (isError) {
        return 'Error loading departments';
      }
      if (field.meta.invalid) {
        return field.meta.error;
      }
      return '';
    };
    return (
      <DataSelectField
        label="Department"
        className={classes.selectField}
        SelectProps={{ displayEmpty: true }}
        disabled={isLoading || disabled}
        error={field.meta.invalid}
        helperText={getHelperText()}
        value={
          data.filter((d) => d.value === value).length === 0
            ? defaultValue.value
            : value
        }
        defaultValue={defaultValue}
        data={data}
        data-e2e="department"
        onChange={(event) => {
          field.input.onChange({
            name: data.find((d) => d.value === event.target.value).label,
            id: event.target.value,
          });
        }}
      />
    );
  };

  return (
    <Field
      name="department"
      component={renderComboBoxField}
      validate={[requiredId]}
    />
  );
};
