// @flow
export const sliceName = 'scopes';

const prefix = `app/${sliceName}/`;
export const DELETE_SCOPE: string = `${prefix}DELETE_SCOPE`;
export const DELETE_SCOPE_FAILED: string = `${prefix}DELETE_SCOPE_FAILED`;
export const DELETE_SCOPE_SUCCESS: string = `${prefix}DELETE_SCOPE_SUCCESS`;
export const LOAD_SCOPES_SUCCESS: string = `${prefix}LOAD_SCOPES_SUCCESS`;
export const SELECT_SCOPE: string = `${prefix}SELECT_SCOPE`;
export const SET_PAGE: string = `${prefix}SET_PAGE`;
export const SET_PAGE_SIZE: string = `${prefix}SET_PAGE_SIZE`;
export const SET_SEARCH_TERM: string = `${prefix}SET_SEARCH_TERM`;
export const SET_SORT_ORDER: string = `${prefix}SET_SORT_ORDER`;
