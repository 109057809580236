// @flow
export const flowEnum = (flowId: number) => {
  switch (flowId) {
    case 0:
      return 'Authorization code';
    case 1:
      return 'Implicit';
    case 2:
      return 'Hybrid';
    case 3:
      return 'Client credentials';
    case 4:
      return 'Resource owner';
    case 5:
      return 'Custom';
    case 6:
      return 'Authorization code with proof key';
    case 7:
      return 'Hybrid with proof key';
    default:
      return undefined;
  }
};

export const productEnum = (type: number) => {
  switch (type) {
    case 0:
      return 'Enterprise';
    case 1:
      return 'CRM';
    case 2:
      return 'Inventory+';
    case 3:
      return 'DealerFire';
    case 4:
      return 'Auto/Mate';
    case 5:
      return 'IDMS';
    default:
      return 'Unknown';
  }
};

export function getProductDisplayName(product: string) {
  switch (product.toLowerCase()) {
    case 'automate':
      return 'Auto/Mate';
    case 'crm':
      return 'CRM';
    case 'dealerfire':
      return 'DealerFire';
    case 'idms':
      return 'IDMS';
    case 'inv':
      return 'Inventory+';
    default:
      return 'Enterprise';
  }
}

export const productToIntEnum = (type: string) => {
  switch (type.toLowerCase()) {
    case 'crm':
      return 1;
    case 'inv':
      return 2;
    case 'web':
      return 3;
    case 'automate':
      return 4;
    case 'idms':
      return 5;
    default:
      return 0;
  }
};

export const refreshTokenUsageEnum = (type: number) => {
  switch (type) {
    case 0:
      return 'Reuse';
    case 1:
      return 'One time only';
    case 2:
      return 'None';
    default:
      return undefined;
  }
};

export const refreshTokenExpirationEnum = (type: number) => {
  switch (type) {
    case 0:
      return 'Sliding';
    case 1:
      return 'Absolute';
    default:
      return undefined;
  }
};
