// @flow
export const formatUserFilters = (userFilterOptions: any) => {
  if (userFilterOptions) {
    const userFilter = {
      page: userFilterOptions.page + 1,
      limit: userFilterOptions.limit,
      orderBy: userFilterOptions.orderBy,
      filterTerm: userFilterOptions.filterTerm,
      sortOrder: userFilterOptions.sortOrder,
      departments: userFilterOptions.appliedDepartments,
      dealerships: userFilterOptions.appliedDealerships,
      status: userFilterOptions.appliedStatus,
      loginRoles: userFilterOptions.appliedLoginRoles,
    };

    return userFilter;
  }
  return null;
};

export const formatUserReportFilters = (userFilterOptions: any) => {
  if (userFilterOptions) {
    const userFilter = {
      page: userFilterOptions.page + 1,
      limit: userFilterOptions.limit,
      orderBy: userFilterOptions.orderBy,
      filterTerm: userFilterOptions.filterTerm,
      status: 'Active',
      sortOrder: userFilterOptions.sortOrder,
      filterNonUpgradedUsers: true,
      dealerships: userFilterOptions.appliedDealerships,
    };

    return userFilter;
  }
  return null;
};
