// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { ConnectedBanner } from 'shared/components/banner/banner-container';
import { formattedEditInfoUser } from 'shared/utils/format-user';
import { getUserRole } from 'shared/utils/get-user-role';
import { ssoRoleLevel } from 'shared/utils/sso-role';
import { disableResetAccountSelector } from '../../state/account-settings.selectors';
import { resetUserAccountThunk } from '../../state/account-settings.thunks';
import { PasswordChange } from '../profile-info/password-change/password-change-component';
import { ProfileInfoComponent } from '../profile-info/profile-info-edit/profile-info-edit.component';
import { AccountSettingsProfile } from '../../account-settings-page/account-settings-nav/account-settings-profile.component';

const styles = () => {
  return {
    contentContainer: {
      marginLeft: 15,
      marginRight: 15,
    },
    profileCard: {
      margin: 'auto',
      marginBottom: 10,
      maxWidth: 493,
    },
    profileInfoEditGroup: {
      margin: 'auto',
      marginBottom: 10,
      maxWidth: 493,
    },
    profileInfoForm: {
      paddingBottom: 10,
    },
    root: {
      flexGrow: 1,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 60,
      maxWidth: 491, // used to align title and content box
    },
  };
};

export type PersonalInfoViewComponentProps = {
  selectedUser: any,
};

type InternalProps = {
  classes: JssClasses,
};

const PersonalInfoViewComponent = (
  props: PersonalInfoViewComponentProps & InternalProps
) => {
  const { selectedUser, classes } = props;

  const dispatch = useDispatch();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const oidcUser = useSelector((state) => state.oidc.user);

  const loginUserRole = getUserRole(oidcUser);

  const hasPermission =
    ssoRoleLevel[loginUserRole] >= ssoRoleLevel.sso_internal;
  const selectedFormattedUser = formattedEditInfoUser(selectedUser);
  const disabled = useSelector((state) => disableResetAccountSelector(state));

  return (
    <div className={classes.root}>
      <ConnectedBanner />
      <div className={classes.contentContainer}>
        <div className={classes.titleContainer}>
          <Typography paragraph variant={TypographyVariants.H4}>
            Account
          </Typography>
          {hasPermission && (
            <Button
              disabled={disabled}
              onClick={() =>
                dispatch(resetUserAccountThunk(selectedFormattedUser))
              }
              data-e2e="resetAccountButton"
            >
              Reset Account
            </Button>
          )}
        </div>
        {md && (
          <Paper className={classes.profileCard} square>
            <AccountSettingsProfile user={selectedUser} />
          </Paper>
        )}
        <div className={classes.profileInfoEditGroup}>
          <div className={classes.profileInfoForm}>
            <ProfileInfoComponent
              initialValues={selectedFormattedUser}
              selectedUser={selectedFormattedUser}
            />
          </div>
          <PasswordChange />
        </div>
      </div>
    </div>
  );
};

export const PersonalInfoView = withStyles(styles)(PersonalInfoViewComponent);
