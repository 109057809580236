// @flow
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getUsernameWithoutPrefix } from '../../../../shared/utils/user-helpers';

export function getAccountDealershipTableRows(
  dealerships: any,
  productType: number
): [] {
  if (dealerships && Array.isArray(dealerships) && productType >= 0) {
    return dealerships.flatMap((ud) =>
      ud.dealershipProducts
        .filter((dp) => dp.productInfo.type === productType)
        .map((udp) => ({
          name: udp.name,
          userName: getUsernameWithoutPrefix(udp.productInfo.userName),
        }))
    );
  }
  return [];
}

export const AccountDealershipTable = (props: any) => {
  const { rows } = props;
  const getSortedRowsAsc = () => {
    return rows.sort((r1, r2) => {
      if (r1.name > r2.name) {
        return 1;
      }
      if (r1.name < r2.name) {
        return -1;
      }
      return 0;
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Store</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getSortedRowsAsc().map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.userName}
              </TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
