// @flow
import { connect } from 'react-redux';
import { clearBannerAction } from './state/banner.actions';
import {
  bannerActionSelector,
  isOpenSelector,
  messageSelector,
  typeSelector,
} from './state/banner.selectors';
import { StyledBanner } from './banner-component';

function mapStateToProps(state) {
  return {
    bannerAction: bannerActionSelector(state),
    isOpen: isOpenSelector(state),
    message: messageSelector(state),
    type: typeSelector(state),
  };
}

const mapDispatchToProps = {
  clearBannerAction,
};

export const ConnectedBanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledBanner);
