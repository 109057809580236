// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadAccountsSettingsThunk } from '../../users/state/users.thunks';
import { willRedirectSelector } from '../../users/state/users.selectors';
import { AccountSettingsPage } from './account-settings-page';

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    willRedirect: willRedirectSelector(state),
  };
}

const mapDispatchToProps = {
  loadAccountsSettingsThunk,
};

export const ConnectedAccountSettingsPage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountSettingsPage)
);
