// @flow
import { Dispatch } from 'redux';
import { setSubmitFailed, startSubmit, stopSubmit, reset } from 'redux-form';
import debounce from '@material-ui/core/utils/debounce';
import { axiosApi, getAppSettings, logout } from '@dealersocket/react-common';
import { passwordFormName } from 'shared/constants';
import { setBannerAction } from 'shared/components/banner/state/banner.actions';
import { formattedEditInfoUserBody } from 'shared/utils/format-user';
import { selectedUserSelector } from '../../users/state/users.selectors';
import { loadUserThunk } from '../../users/state/users.thunks';
import {
  checkUsernameAction,
  checkUsernameFailedAction,
  checkUsernameSuccessAction,
  enableResetPasswordAction,
  resetPasswordAction,
  resetAccountAction,
  resetAccountFailedAction,
  sendAccountSetupAction,
  sendAccountSetupSucesssAction,
  sendAccountSetupFailedAction,
  sendEmailVerificationAction,
  sendEmailVerificationFailedAction,
  sendEmailVerificationSuccessAction,
  setInvalidPasswordAction,
  setDuplicatePasswordAction,
} from './account-settings.actions';

export function changePasswordThunk(passwordRequest: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const request = {
      oldPassword: passwordRequest.currentPassword,
      newPassword: passwordRequest.newPassword,
    };

    return axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${
        passwordRequest.id
      }/ChangePasswordRequest`,
      {
        method: 'post',
        data: request,
      }
    )
      .then(() => {
        dispatch(reset(passwordFormName));
        dispatch(setDuplicatePasswordAction(false));
        dispatch(setInvalidPasswordAction(false));
        dispatch(
          setBannerAction({
            message: 'Password change successful',
          })
        );
      })
      .catch((errorResponse) => {
        if (
          Array.isArray(errorResponse.response.data) &&
          errorResponse.response.data.filter(
            (error) => error.code === 'DuplicatePassword'
          ).length > 0
        ) {
          dispatch(setDuplicatePasswordAction(true));
        } else {
          dispatch(setInvalidPasswordAction(true));
        }
        dispatch(setSubmitFailed(passwordFormName));
        return Promise.reject(errorResponse);
      })
      .finally(() => {
        dispatch(stopSubmit(passwordFormName));
      });
  };
}

const checkUsernameDebounce = debounce(
  (dispatch: Dispatch, user, username) =>
    setTimeout(() => {
      dispatch(checkUsernameAction());

      if (user.userName.toLowerCase() === username.toLowerCase()) {
        dispatch(checkUsernameSuccessAction(true));
      } else {
        axiosApi(
          `${
            getAppSettings().ssoApiUrl
          }/Users/IsUsernameUnique/${username.toLowerCase()}`
        )
          .then((payload) => dispatch(checkUsernameSuccessAction(payload)))
          .catch(() => dispatch(checkUsernameFailedAction()));
      }
    }),
  300
);

export function checkUsernameThunk(username: string) {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const user = selectedUserSelector(getState());
    checkUsernameDebounce(dispatch, user, username);
  };
}

export function editUserInfoThunk(user: any) {
  return async (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const { ...formattedBody } = formattedEditInfoUserBody(user);
    const state = getState();
    const currentUser = state.users.selectedUser;

    dispatch(startSubmit('profileInfo_edit_form'));
    return axiosApi(`${getAppSettings().ssoApiUrl}/Users/${user.id}`, {
      method: 'put',
      data: JSON.stringify(formattedBody),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((payload) => {
        if (currentUser.email !== formattedBody.email) {
          dispatch(
            setBannerAction({
              message: `An email has been sent to ${
                formattedBody.email
              } with instructions to verify the email address.`,
            })
          );
        }
        dispatch(loadUserThunk(payload.id));
      })
      .catch((error) => {
        dispatch(setSubmitFailed('profileInfo_edit_form'));
        let errorMessage = 'Unable to save user record. Please try again.';
        if (error && error.response && error.response.data) {
          errorMessage = error.response.data;
        }
        dispatch(
          setBannerAction({
            message: errorMessage,
            type: 'error',
            bannerAction: editUserInfoThunk(user),
          })
        );
        return Promise.reject(error);
      });
  };
}

export function resetPasswordThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const { email, id } = user;
    dispatch(resetPasswordAction());
    return axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${id}/PasswordResetRequests`,
      {
        method: 'post',
      }
    )
      .then((response) => {
        dispatch(
          setBannerAction({
            message: response.message,
          })
        );
      })
      .catch((errorResponse) => {
        dispatch(
          setBannerAction({
            message: `We were unable to send an email to ${email}. Please try again.`,
            type: 'error',
            bannerAction: resetPasswordThunk(user),
          })
        );
        return Promise.reject(errorResponse);
      })
      .finally(() => dispatch(enableResetPasswordAction(true)));
  };
}

export function resetUserAccountThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const { id } = user;

    dispatch(resetAccountAction());
    axiosApi(`${getAppSettings().ssoApiUrl}/Users/${id}/ResetAccount`, {
      method: 'post',
    })
      .then(() => logout())
      .catch((response) =>
        dispatch(
          setBannerAction({
            message: response.message,
            type: 'error',
            bannerAction: resetUserAccountThunk(user),
          })
        )
      )
      .then(() => dispatch(resetAccountFailedAction()));
  };
}

export function sendAccountSetupThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const { email, id } = user;
    dispatch(sendAccountSetupAction());
    return axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${id}/ResendWelcomeEmail`,
      {
        method: 'post',
      }
    )
      .then((response) => {
        dispatch(
          setBannerAction({
            message: response.message,
          })
        );
      })
      .then(() => dispatch(sendAccountSetupSucesssAction()))
      .catch((errorResponse) => {
        dispatch(sendAccountSetupFailedAction());
        dispatch(
          setBannerAction({
            message: `We were unable to send an email to ${email}. Please try again.`,
            type: 'error',
            bannerAction: sendAccountSetupThunk(user),
          })
        );
        return Promise.reject(errorResponse);
      });
  };
}

export function sendEmailVerificationThunk(user: any) {
  return async (dispatch: Dispatch): Promise<void> => {
    const { email, id } = user;
    dispatch(sendEmailVerificationAction());
    return axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${id}/SendEmailVerification`,
      {
        method: 'post',
      }
    )
      .then((response) => {
        dispatch(
          setBannerAction({
            message: response.message,
          })
        );
      })
      .then(() => dispatch(sendEmailVerificationSuccessAction()))
      .catch((errorResponse) => {
        dispatch(sendEmailVerificationFailedAction());
        dispatch(
          setBannerAction({
            message: `We were unable to send an email to ${email}. Please try again.`,
            type: 'error',
            bannerAction: sendEmailVerificationThunk(user),
          })
        );
        return Promise.reject(errorResponse);
      });
  };
}

export function uploadProfileImageThunk(userId: string, image: File) {
  return async (dispatch: Dispatch): Promise<void> => {
    const data = new FormData();
    data.append('file', image);

    return axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${userId}/ProfileImage`,
      {
        method: 'post',
        data,
      }
    )
      .then((response) => {
        dispatch(
          setBannerAction({
            message: response.message,
          })
        );
      })
      .catch((errorResponse) => {
        dispatch(
          setBannerAction({
            message: `We were unable to upload the image. Please try again.`,
            type: 'error',
            bannerAction: uploadProfileImageThunk(userId, image),
          })
        );
        return Promise.reject(errorResponse);
      });
  };
}
