// @flow
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Redirect, Switch } from 'react-router-dom';
import { AccountSettingsView } from './account-settings-view/account-settings-view.component';

const styles = () => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 44px)',
    },
  };
};

export type AccountSettingsPageProps = {
  loadAccountsSettingsThunk: any,
  willRedirect: any,
};

type InternalProps = {
  classes: JssClasses,
};

const AccountSettingsPageComponent = (
  props: AccountSettingsPageProps & InternalProps
) => {
  const { loadAccountsSettingsThunk, willRedirect, classes } = props;

  useEffect(() => {
    loadAccountsSettingsThunk();
  }, []);

  return (
    <div className={classes.content}>
      <Switch>
        {willRedirect === true ? (
          <Redirect to="/forbidden" />
        ) : (
          <AccountSettingsView />
        )}
      </Switch>
    </div>
  );
};

export const AccountSettingsPage = withStyles(styles)(
  AccountSettingsPageComponent
);
