// @flow
export const sliceName = 'accountSettings';

const prefix = `app/${sliceName}/`;
export const CHECK_USERNAME: string = `${prefix}CHECK_USERNAME`;
export const CHECK_USERNAME_SUCCESS: string = `${prefix}CHECK_USERNAME_SUCCESS`;
export const CHECK_USERNAME_FAILED: string = `${prefix}CHECK_USERNAME_FAILED`;
export const ENABLE_RESET_PASSWORD: string = `${prefix}ENABLE_RESET_PASSWORD`;
export const RESET_ACCOUNT: string = `${prefix}RESET_ACCOUNT`;
export const RESET_ACCOUNT_FAILED: string = `${prefix}RESET_ACCOUNT_FAILED`;
export const RESET_PASSWORD: string = `${prefix}RESET_PASSWORD`;
export const SEND_ACCOUNT_SETUP: string = `${prefix}SEND_ACCOUNT_SETUP`;
export const SEND_ACCOUNT_SETUP_SUCCESS: string = `${prefix}SEND_ACCOUNT_SETUP_SUCCESS`;
export const SEND_ACCOUNT_SETUP_FAILED: string = `${prefix}SEND_ACCOUNT_SETUP_FAILED`;
export const SEND_EMAIL_VERIFICATION: string = `${prefix}SEND_EMAIL_VERIFICATION`;
export const SEND_EMAIL_VERIFICATION_SUCCESS: string = `${prefix}SEND_EMAIL_VERIFICATION_SUCCESS`;
export const SEND_EMAIL_VERIFICATION_FAILED: string = `${prefix}SEND_EMAIL_VERIFICATION_FAILED`;
export const SET_CHANGE_PASSWORD_DIALOG: string = `${prefix}SET_CHANGE_PASSWORD_DIALOG`;
export const SET_DUPLICATE_PASSWORD: string = `${prefix}SET_DUPLICATE_PASSWORD`;
export const SET_INVALID_PASSWORD: string = `${prefix}SET_INVALID_PASSWORD`;
