// @flow
import { createSelector } from 'reselect';

const selectSlice = (state: any) => state.banner;

export const messageSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.message;
  }
);

export const bannerActionSelector = createSelector(
  selectSlice,
  (slice) => slice.bannerAction
);

export const typeSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.type;
  }
);

export const isOpenSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.isOpen;
  }
);
