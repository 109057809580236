// @flow
/**
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { withStyles } from '@material-ui/core/styles';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import React from 'react';
import { withRouter } from 'react-router';
import { Routes } from '../../routes';
import { ConnectedAppHeader as AppHeader } from '../components/app-header/app-header';
import { LeftNav } from '../components/left-nav/left-nav';

type InternalProps = {
  classes: JssClasses,
};

const AppRootComponent = (props: InternalProps) => {
  const { classes = {} } = props;

  return (
    <div className={classes.root}>
      <AppHeader />
      <LeftNav />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
};

const styles = (theme: Object) => {
  return {
    root: {
      height: 'calc(100vh)',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: theme.mixins.toolbar,
  };
};

export const AppRoot = withStyles(styles)(withRouter(AppRootComponent));
