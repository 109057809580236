// @flow
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';
import { connect } from 'react-redux';
import { styles } from '../left-nav-item/left-nav-item';
import {
  setLeftNavChangedByUserAction,
  setLeftNavExpandedAction,
} from '../left-nav/state/left-nav.actions';

type LeftNavFooterProps = {
  expanded: boolean,
  setLeftNavChangedByUserAction: () => void,
  setLeftNavExpandedAction: (expand: boolean) => void,
};
type InternalProps = {
  classes: JssClasses,
};

const LeftNavFooterComponent = (props: LeftNavFooterProps & InternalProps) => {
  const { classes, expanded } = props;
  return expanded ? (
    <MenuItem
      className={classes.leftNavFooterMenuItem}
      onClick={() => {
        props.setLeftNavChangedByUserAction();
        props.setLeftNavExpandedAction(false);
      }}
      value="collapse"
      data-e2e="left-nav-footer-collapse-button"
    >
      <ChevronLeft />
      <Typography variant={TypographyVariants.Subtitle1}>Collapse</Typography>
    </MenuItem>
  ) : (
    <MenuItem
      className={classes.leftNavFooterMenuItem}
      onClick={() => {
        props.setLeftNavChangedByUserAction();
        props.setLeftNavExpandedAction(true);
      }}
      value="expand"
      data-e2e="left-nav-footer-expand-button"
    >
      <ChevronRight />
    </MenuItem>
  );
};

const LeftNavFooterHolder = withStyles(styles)(LeftNavFooterComponent);

const mapDispatchToProps = {
  setLeftNavChangedByUserAction,
  setLeftNavExpandedAction,
};

export const LeftNavFooter = connect(
  null,
  mapDispatchToProps
)(LeftNavFooterHolder);
