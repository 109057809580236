// @flow
import { createSelector } from 'reselect';

const selectSlice = (state: any) => state.leftNav;

export const isLeftNavOpenSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.open;
  }
);

export const isLeftNavChangedByUserSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.changedByUser;
  }
);

export const isLeftNavExpandedSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.expanded;
  }
);
