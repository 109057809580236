// @flow
import * as React from 'react';
import { reduxForm } from 'redux-form';

type FormProps = {
  children?: any,
  className?: any,
  handleSubmit: any,
};

const FormCore = (props: FormProps): React.ReactElement => {
  return (
    <form className={props.className} noValidate onSubmit={props.handleSubmit}>
      {props.children}
    </form>
  );
};

export const Form: any = reduxForm({
  // a unique name for the form
  enableReinitialize: true,
  form: 'pageForm',
})(FormCore);
