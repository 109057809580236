// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import type { JssClasses } from '@dealersocket/ds-ui-react/types/styles';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { ConnectedPasswordForm } from 'shared/components/password/password-form.component';
import { changePasswordThunk } from 'area/account-settings/state/account-settings.thunks';

const styles = (theme) => {
  return {
    contentPaper: {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      padding: '20px 27px 26px 27px',
      '&.MuiPaper-elevation1': {
        border: 'solid 1px #d8d8d8',
      },
    },
    passwordGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    updatePasswordButton: {
      width: 180,
    },
  };
};

type InternalProps = {
  classes: JssClasses,
};

const PasswordChangeComponent = (props: InternalProps) => {
  const { classes } = props;

  const oidcUser = useSelector((state) => state.oidc.user);

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const payload = {
      ...values,
      id: oidcUser.profile.sub,
    };
    dispatch(changePasswordThunk(payload)).then(() => {
      return true;
    });
  };

  return (
    <Paper square className={classes.contentPaper}>
      <Grid container direction="row" justify="space-between">
        <div className={classes.passwordGroup}>
          <Typography variant={TypographyVariants.H6}>
            Reset Password
          </Typography>
          <ConnectedPasswordForm requireCurrentPassword onSubmit={onSubmit} />
        </div>
      </Grid>
    </Paper>
  );
};

export const PasswordChange = withStyles(styles)(PasswordChangeComponent);
