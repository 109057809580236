// @flow
import globToRe from 'glob-to-regexp';
import { axiosResult, getAppSettings } from '@dealersocket/react-common';

export function mockApi(axiosMock: any): any {
  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/LoginProviders*`))
    .reply(() => {
      const loginProviders = [];

      for (let index = 1; index <= 20; index += 1) {
        loginProviders.push({
          name: `mock_login_provider${index}`,
          displayName: `Mock Login Provider ${index < 10 ? '0' : ''}${index}`,
          entityId: `mockEntityId${index}`,
          metadataUrl: `http://www.dealersocket.com/metadata/${index}`,
          dealershipGroupId: index % 3 ? '3' : '',
          enabled: index % 7 === 1,
          created: new Date(new Date().setDate(new Date().getDate() - 5)),
          lastModified: Date.now(),
        });
      }
      return axiosResult(loginProviders);
    });

  axiosMock
    .onPost(globToRe(`${getAppSettings().ssoApiUrl}/LoginProviders*`))
    .reply((config) => {
      return axiosResult(config.data);
    });

  axiosMock
    .onPut(globToRe(`${getAppSettings().ssoApiUrl}/LoginProviders*`))
    .reply((config) => {
      return axiosResult(config.data);
    });

  axiosMock
    .onDelete(globToRe(`${getAppSettings().ssoApiUrl}/LoginProviders*`))
    .reply(() => {
      return axiosResult('');
    });
}
