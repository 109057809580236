// @flow
import { createSelector } from 'reselect';

const selectSlice = (state: any) => state.departments;

export const departmentsSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.departments;
  }
);

export const isLoadingSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.isLoading;
  }
);

export const isErrorSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.isError;
  }
);
