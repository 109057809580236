// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import { filterEntities, sortEntities } from 'shared/utils/sort-and-filter';
import {
  DELETE_CLIENT,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_SUCCESS,
  LOAD_CLIENTS_SUCCESS,
  SELECT_CLIENT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  SHOW_ALL_SCOPES_SECTION,
  sliceName,
} from './clients.types';

const initialState = {
  selectedClient: null,
  clients: [],
  totalClients: 0,
  clientsLoaded: false,
  clientsPageTableOptions: {
    page: 0,
    limit: 25,
    filterTerm: '',
    sortOrder: 'asc',
    sortTerm: 'clientName',
  },
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case DELETE_CLIENT:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_CLIENT_FAILED:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case LOAD_CLIENTS_SUCCESS: {
      if (action.payload)
        action.payload.forEach((client) => (client.id = client.clientId)); // eslint-disable-line

      const filteredClients = filterEntities(
        action.payload,
        'clientName',
        state.clientsPageTableOptions.filterTerm
      );
      return {
        ...state,
        clientsLoaded: true,
        clients: sortEntities(filteredClients, state.clientsPageTableOptions),
        totalClients: filteredClients.length,
      };
    }
    case SELECT_CLIENT:
      if (state.selectedClient !== action.payload) {
        return {
          ...state,
          selectedClient: action.payload,
        };
      }
      return state;
    case SET_PAGE:
      if (action.payload !== state.clientsPageTableOptions.page) {
        return {
          ...state,
          clientsPageTableOptions: {
            ...state.clientsPageTableOptions,
            page: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    case SET_PAGE_SIZE:
      if (action.payload !== state.clientsPageTableOptions.limit) {
        return {
          ...state,
          clientsPageTableOptions: {
            ...state.clientsPageTableOptions,
            limit: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SEARCH_TERM:
      if (action.payload !== state.clientsPageTableOptions.filterTerm) {
        return {
          ...state,
          clientsPageTableOptions: {
            ...state.clientsPageTableOptions,
            filterTerm: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SORT_ORDER: {
      const { sortOrder, sortTerm } = action.payload;
      if (
        state.clientsPageTableOptions.sortOrder !== sortOrder ||
        state.clientsPageTableOptions.sortTerm !== sortTerm
      ) {
        return {
          ...state,
          clientsPageTableOptions: {
            ...state.clientsPageTableOptions,
            sortOrder,
            sortTerm,
          },
        };
      }
      return {
        ...state,
      };
    }
    case SHOW_ALL_SCOPES_SECTION:
      if (state.showAllScopes !== action.payload) {
        return {
          ...state,
          showAllScopes: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
