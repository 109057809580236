// @flow
import React from 'react';
import { connect, useSelector } from 'react-redux';
import Help from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { getAppSettings, logout } from '@dealersocket/react-common';
import { EnterpriseAppBar } from '@dealersocket/ds-ui-react/EnterpriseAppBar';
import { toggleLeftNavOpenAction } from '../left-nav/state/left-nav.actions';
import { titleLogos } from './app-header.logos';

const styles = (theme) => {
  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 200,
    },
    helpIcon: {
      fontSize: '24px',
    },
    menuIcon: {
      color: theme.palette.grey[700],
      fontSize: '24px',
      marginLeft: 12,
      marginRight: 20,
    },
    menuItem: {
      fontSize: '16px',
      paddingRight: 32,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  };
};

type AppHeaderProps = {
  classes: JssClasses,
  toggleLeftNavOpenAction: () => void,
};

const AppHeaderComponent = (props: AppHeaderProps) => {
  const { classes } = props;

  const oidcUser = useSelector((state) => state.oidc.user);

  if (oidcUser === null) {
    return <div />;
  }

  const actionIcons = [
    {
      key: 'Help',
      logo: <Help data-e2e="helpButton" className={classes.helpIcon} />,
    },
  ];

  return (
    <EnterpriseAppBar
      classes={{
        container: classes.appBar,
      }}
      data-e2e="app-bar"
      actionIcons={actionIcons}
      accessToken={oidcUser.access_token}
      product="ent"
      ssoUrl={getAppSettings().ssoUrl}
      menuWidth={500}
      titleLogos={titleLogos}
      onMenuButtonClick={props.toggleLeftNavOpenAction}
      logoutCallback={() => logout()}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

const mapDispatchToProps = {
  toggleLeftNavOpenAction,
};

const AppHeader = withStyles(styles)(AppHeaderComponent);

export const ConnectedAppHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
