// @flow
export const leftNavWidth = 230;
export const collapsedLeftNavWidth = 52;
export const drawerWidth = 300;

export const passwordFormName = 'passwordForm';

export const products = ['Crm', 'Web', 'Inv', 'Automate', 'Idms'];

export const accountRoute = '/account/settings/personalinfo';
export const accountDealershipsRoute = '/account/settings/dealerships';
export const accountAccessRoute = '/account/settings/access';

export const maxAllowedMegaBytes = 5;
export const maxAllowedBytes = 1048576 * maxAllowedMegaBytes;

// Dialog Names
export const customerReassignmentConfirmDialogName =
  'customerReassignmentConfirm';

export const deactivationConfirmDialogName = 'deactivationConfirm';

export const removeAccessConfirmDialogName = 'removeAccessConfirm';

export const defaultInputProps = {
  autoCapitalize: 'none',
  autoCorrect: 'off',
  spellCheck: 'false',
};
