// @flow
export function isExternalLoginUser(user) {
  return (
    user &&
    user.profile &&
    Array.isArray(user.profile.amr) &&
    user.profile.amr[0] &&
    user.profile.amr[0].toLowerCase() === 'external'
  );
}
