// @flow
function polyfill() {
  Element.prototype.remove = function remove() {
    this.parentElement.removeChild(this);
  };

  HTMLCollection.prototype.remove = function remove() {
    for (let i = this.length - 1; i >= 0; i -= 1) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  };

  NodeList.prototype.remove = HTMLCollection.prototype.remove;
}

module.exports = {
  polyfill,
};
