// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  SET_REASSIGNMENT_INFO,
  sliceName,
} from './customer-reassignment.types';

export const initialState = {
  reassignmentInfo: {
    siteId: null,
    username: null,
  },
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_REASSIGNMENT_INFO:
      return {
        ...state,
        reassignmentInfo: action.payload,
      };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
