// @flow
import React from 'react';
import { Route } from 'react-router';
import { type Component, Redirect } from 'react-router-dom';

type PrivateRouteProps = {
  component: Component,
  isAuthorized?: boolean,
};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { isAuthorized, component, ...other } = props;

  return (
    <Route
      {...other}
      render={(routeProps) =>
        isAuthorized ? (
          renderMergedProps(component, routeProps, other)
        ) : (
          <Redirect
            to={{
              pathname: '/forbidden',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};
