// @flow
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

function dataURLtoFile(dataUrl) {
  const arr = dataUrl.split(',');
  const mime = getMimeType(dataUrl);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  const filename = `profileImage`;

  for (n = bstr.length; n >= 0; n -= 1) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let file = null;
  try {
    file = new File([u8arr], filename, { type: mime });
  } catch (e) {
    file = getFileForInternetExplorer(u8arr, filename, mime);
  }
  return file;
}

function getMimeType(dataUrl) {
  const arr = dataUrl.split(',');
  // eslint-disable-next-line no-useless-escape
  return arr[0].match(/^data:([a-zA-Z\/-]*)/)[1];
}

function getFileForInternetExplorer(u8arr, filename, type) {
  const lastModified = new Date();

  const blob = new Blob([u8arr], { type });
  blob.fileName = filename;
  blob.name = filename;
  blob.lastModifiedDate = lastModified;

  return Object.assign(blob, { lastModified, type });
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flipHorizontal,
  flipVertical
) {
  const image = await createImage(imageSrc);
  const mimeType = getMimeType(imageSrc);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  context.translate(safeArea / 2, safeArea / 2);
  context.rotate(getRadianAngle(rotation));

  if (flipHorizontal) {
    context.scale(-1, 1);
  }
  if (flipVertical) {
    context.scale(1, -1);
  }

  context.translate(-safeArea / 2, -safeArea / 2);
  // draw rotated image and store data.
  context.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = context.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  context.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
  const resized = await resizeImage(canvas.toDataURL(mimeType), mimeType);

  // As a blob
  return new Promise((resolve) => {
    resolve(dataURLtoFile(resized));
  });
}

const resizeImage = (url, mimeType) =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 300; // target width
    canvas.height = 300; // target height

    const image = new Image();
    image.addEventListener('load', () => {
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      resolve(canvas.toDataURL(mimeType));
    });
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });
