// @flow
export const sliceName = 'loginProviders';

const prefix = `app/${sliceName}/`;
export const DELETE_LOGINPROVIDER: string = `${prefix}DELETE_LOGINPROVIDER`;
export const DELETE_LOGINPROVIDER_SUCCESS: string = `${prefix}DELETE_LOGINPROVIDER_SUCCESS`;
export const DELETE_LOGINPROVIDER_FAILED: string = `${prefix}DELETE_LOGINPROVIDER_FAILED`;
export const LOAD_LOGINPROVIDERS_SUCCESS: string = `${prefix}LOAD_LOGINPROVIDERS_SUCCESS`;
export const SELECT_LOGINPROVIDER: string = `${prefix}SELECT_LOGINPROVIDER`;
export const SET_PAGE: string = `${prefix}SET_PAGE`;
export const SET_PAGE_SIZE: string = `${prefix}SET_PAGE_SIZE`;
export const SET_SEARCH_TERM: string = `${prefix}SET_SEARCH_TERM`;
export const SET_SORT_ORDER: string = `${prefix}SET_SORT_ORDER`;
