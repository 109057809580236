// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSwipeable } from 'react-swipeable';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import {
  accountRoute,
  accountDealershipsRoute,
  accountAccessRoute,
} from 'shared/constants';
import { getUserRole } from 'shared/utils/get-user-role';
import { ssoRoleLevel } from 'shared/utils/sso-role';
import { TabPanel } from 'shared/components/tab-panel/tab-panel.component';
import { ConnectedPersonalInfoPage } from '../../personal-info-page/personal-info-page.container';
import { DealershipAccess } from '../../personal-info-page/dealership-access/dealership-access.component';
import { ProductAccess } from '../../personal-info-page/product-access/product-access.component';
import { AccountSettingsNav } from '../account-settings-nav/account-settings-nav.component';

const styles = (theme) => {
  return {
    div: {
      overflow: 'auto',
      width: '100%',
    },
    root: {
      backgroundColor: theme.palette.grey[200],
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        marginTop: 52,
      },
    },
    swipeableView: {
      flexGrow: 1,
      overflow: 'auto',
      paddingBottom: 150,
      [theme.breakpoints.down('md')]: {
        height: 'calc(100% - 96px)',
        position: 'fixed',
        width: 'calc(100% - 50px)',
      },
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
  };
};

type InternalProps = {
  classes: JssClasses,
};

const AccountSettingsViewComponent = (props: InternalProps) => {
  const { classes } = props;

  const oidcUser = useSelector((state) => state.oidc.user);
  const role = getUserRole(oidcUser);
  const isInternalAdmin = ssoRoleLevel[role] === ssoRoleLevel.sso_internal;

  const history = useHistory();
  const [index, setIndex] = React.useState(0);
  const [slideDirection, setSlideDirection] = React.useState('right');

  let displayedTabs = [
    {
      component: <ConnectedPersonalInfoPage />,
      direction: 'right',
      route: accountRoute,
    },
    {
      component: <DealershipAccess />,
      direction: slideDirection,
      route: accountDealershipsRoute,
    },
    {
      component: <ProductAccess />,
      direction: 'left',
      route: accountAccessRoute,
    },
  ];

  if (!isInternalAdmin) {
    displayedTabs = displayedTabs.filter(
      (tab) => tab.route !== accountDealershipsRoute
    );
  }

  const handleChangeIndex = (newIndex) => {
    history.push(displayedTabs[newIndex].route);
    setIndex(newIndex);
  };

  const config = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSlideDirection('left');
      if (index + 1 < displayedTabs.length) {
        handleChangeIndex(index + 1);
      }
    },
    onSwipedRight: () => {
      setSlideDirection('right');
      if (index - 1 >= 0) {
        handleChangeIndex(index - 1);
      }
    },
    ...config,
  });

  React.useEffect(() => {
    if (history.location.pathname.includes(accountRoute)) {
      setIndex(displayedTabs.findIndex((tab) => tab.route === accountRoute));
    } else if (history.location.pathname.includes(accountDealershipsRoute)) {
      setIndex(
        displayedTabs.findIndex((tab) => tab.route === accountDealershipsRoute)
      );
    } else if (history.location.pathname.includes(accountAccessRoute)) {
      setIndex(
        displayedTabs.findIndex((tab) => tab.route === accountAccessRoute)
      );
    }
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <AccountSettingsNav index={index} setIndex={setIndex} />
      <div {...handlers} className={classes.swipeableView}>
        {displayedTabs.map((tab, tabIndex) => {
          return (
            <TabPanel key={tab.route} value={index} index={tabIndex}>
              <Slide
                direction={tab.direction}
                in={index === tabIndex}
                mountOnEnter
                unmountOnExit
              >
                <div className={classes.div}>{tab.component}</div>
              </Slide>
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

export const AccountSettingsView = withStyles(styles)(
  AccountSettingsViewComponent
);
