// @flow
export const sliceName = 'clients';

const prefix = `app/${sliceName}/`;

export const DELETE_CLIENT: string = `${prefix}DELETE_CLIENT`;
export const DELETE_CLIENT_FAILED: string = `${prefix}DELETE_CLIENT_FAILED`;
export const DELETE_CLIENT_SUCCESS: string = `${prefix}DELETE_CLIENT_SUCCESS`;
export const LOAD_CLIENTS_SUCCESS: string = `${prefix}LOAD_CLIENTS_SUCCESS`;
export const SELECT_CLIENT: string = `${prefix}SELECT_CLIENT`;
export const SET_PAGE: string = `${prefix}SET_PAGE`;
export const SET_PAGE_SIZE: string = `${prefix}SET_PAGE_SIZE`;
export const SET_SEARCH_TERM: string = `${prefix}SET_SEARCH_TERM`;
export const SET_SORT_ORDER: string = `${prefix}SET_SORT_ORDER`;
export const SHOW_ALL_SCOPES_SECTION: string = `${prefix}SHOW_ALL_SCOPES_SECTION`;
