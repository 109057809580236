// @flow
import { productToIntEnum } from './enums';
import { products } from '../constants';

export const formattedUser = (selectedUser: any) =>
  selectedUser && {
    ...selectedUser,
    ssoAdmin: selectedUser.roles && selectedUser.roles.includes('sso_internal'),
    impersonation: selectedUser.roles && selectedUser.roles.includes('imp'),
    products: products.map((productType) => {
      let product = { type: productToIntEnum(productType) };
      if (selectedUser && selectedUser.products) {
        const userProduct = selectedUser.products.find(
          (p) => p.type === productToIntEnum(productType)
        );
        if (userProduct) {
          product = userProduct;
        }
      }

      return {
        ...product,
        admin: product.roles && product.roles.includes('admin'),
      };
    }),
  };

export const formattedEditInfoUser = (selectedUser: any) =>
  selectedUser && {
    ...selectedUser,
    ssoRole:
      (selectedUser.roles &&
        selectedUser.roles.find((role) => role.startsWith('sso_'))) ||
      '',
    impersonation: selectedUser.roles && selectedUser.roles.includes('imp'),
    products: products.map((productType) => {
      let product = { type: productToIntEnum(productType) };
      if (selectedUser && selectedUser.products) {
        const userProduct = selectedUser.products.find(
          (p) => p.type === productToIntEnum(productType)
        );
        if (userProduct) {
          product = userProduct;
        }
      }

      return {
        ...product,
        admin: product.roles && product.roles.includes('admin'),
      };
    }),
  };

export const formattedUserBody = (user: any) =>
  user && {
    ...user,
    name: `${user.firstName} ${user.lastName}`,
    products:
      user.products &&
      user.products.map((product) => ({
        ...product,
        dealerships:
          typeof product.dealerships === 'string'
            ? product.dealerships.split(',').map((x) => x.trim())
            : product.dealerships,
        roles: [product.admin && 'admin'].filter((x) => !!x !== false),
      })),
    roles: [
      user.ssoAdmin && 'sso_internal',
      user.impersonation && 'imp',
    ].filter((x) => !!x !== false),
  };

export const formattedEditInfoUserBody = (user: any) =>
  user && {
    ...user,
    name: `${user.firstName} ${user.lastName}`,
    roles: [user.ssoRole, user.impersonation && 'imp'].filter(
      (role) => !!role !== false
    ),
  };
