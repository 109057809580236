// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import { filterEntities, sortEntities } from 'shared/utils/sort-and-filter';
import {
  DELETE_LOGINPROVIDER,
  DELETE_LOGINPROVIDER_FAILED,
  DELETE_LOGINPROVIDER_SUCCESS,
  LOAD_LOGINPROVIDERS_SUCCESS,
  SELECT_LOGINPROVIDER,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  sliceName,
} from './login-providers.types';

const initialState = {
  selectedLoginProvider: null,
  loginProviders: [],
  totalLoginProviders: 0,
  loginProvidersLoaded: false,
  loginProvidersPageTableOptions: {
    page: 0,
    limit: 25,
    filterTerm: '',
    sortOrder: 'asc',
    sortTerm: 'displayName',
  },
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case DELETE_LOGINPROVIDER:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_LOGINPROVIDER_FAILED:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_LOGINPROVIDER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case LOAD_LOGINPROVIDERS_SUCCESS: {
      if (action.payload) {
        action.payload.forEach(
          (loginProvider) => (loginProvider.id = loginProvider.name) // eslint-disable-line
        );
      }

      const filteredLoginProviders = filterEntities(
        action.payload,
        'displayName',
        state.loginProvidersPageTableOptions.filterTerm
      );
      return {
        ...state,
        loginProvidersLoaded: true,
        loginProviders: sortEntities(
          filteredLoginProviders,
          state.loginProvidersPageTableOptions
        ),
        totalLoginProviders: filteredLoginProviders.length,
      };
    }
    case SELECT_LOGINPROVIDER:
      if (state.selectedLoginProvider !== action.payload) {
        return {
          ...state,
          selectedLoginProvider: action.payload,
        };
      }
      return state;
    case SET_PAGE:
      if (action.payload !== state.loginProvidersPageTableOptions.page) {
        return {
          ...state,
          loginProvidersPageTableOptions: {
            ...state.loginProvidersPageTableOptions,
            page: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    case SET_PAGE_SIZE:
      if (action.payload !== state.loginProvidersPageTableOptions.limit) {
        return {
          ...state,
          loginProvidersPageTableOptions: {
            ...state.loginProvidersPageTableOptions,
            limit: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SEARCH_TERM:
      if (action.payload !== state.loginProvidersPageTableOptions.filterTerm) {
        return {
          ...state,
          loginProvidersPageTableOptions: {
            ...state.loginProvidersPageTableOptions,
            filterTerm: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SORT_ORDER: {
      const { sortOrder, sortTerm } = action.payload;
      if (
        state.loginProvidersPageTableOptions.sortOrder !== sortOrder ||
        state.loginProvidersPageTableOptions.sortTerm !== sortTerm
      ) {
        return {
          ...state,
          loginProvidersPageTableOptions: {
            ...state.loginProvidersPageTableOptions,
            sortOrder,
            sortTerm,
          },
        };
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
