// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDirty, isSubmitting, isValid, reduxForm, touch } from 'redux-form';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import type { JssClasses } from '@dealersocket/ds-ui-react/types/styles';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import { TextField } from '@dealersocket/ds-ui-react/fields/TextField/index';
import { defaultInputProps } from 'shared/constants';
import {
  required,
  validEmailPoliteErrorMessage,
} from 'shared/utils/validation';
import { getUserRole } from 'shared/utils/get-user-role';
import { isInAdminRole } from 'shared/utils/sso-role';
import { DepartmentEdit } from '../../../../departments/department/department-edit.component';
import { profileInfoFormName } from './form-name';

type ExternalProps = {
  handleSubmit: any,
  user: any,
};

type InternalProps = {
  classes: JssClasses,
};

const styles = (theme) => {
  return {
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    textFieldButtonContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    unverified: {
      '& svg': {
        color: theme.palette.error.main,
      },
    },
    verified: {
      '& svg': {
        color: theme.palette.secondary.main,
      },
    },
    warningText: {
      marginBottom: 15,
      marginTop: 30,
    },
  };
};

const FormCore = (props: ExternalProps & InternalProps): React.ReactElement => {
  const { classes, handleSubmit, user } = props;

  const dispatch = useDispatch();

  const isFormSubmitting = useSelector((state) =>
    isSubmitting(profileInfoFormName)(state)
  );

  const isFormDirty = useSelector((state) =>
    isDirty(profileInfoFormName)(state)
  );

  const isFormValid = useSelector((state) =>
    isValid(profileInfoFormName)(state)
  );

  const oidcUser = useSelector((state) => state.oidc.user);
  const role = getUserRole(oidcUser);
  const canEdit = isInAdminRole(role);
  const warningMessage =
    'Changing your email address will update your login email going forward';
  return (
    <form onSubmit={handleSubmit}>
      <FormTextField
        data-e2e="firstNameField"
        disabled={isFormSubmitting}
        fullWidth
        inputProps={defaultInputProps}
        label="First name"
        name="firstName"
        onFocus={() => dispatch(touch('firstName'))}
        validate={[required]}
      />
      <FormTextField
        data-e2e="lastNameField"
        disabled={isFormSubmitting}
        fullWidth
        inputProps={defaultInputProps}
        label="Last name"
        name="lastName"
        onFocus={() => dispatch(touch('lastName'))}
        validate={[required]}
      />
      <FormTextField
        data-e2e="jobTitleField"
        disabled={isFormSubmitting || !canEdit}
        fullWidth
        inputProps={defaultInputProps}
        label="Job title"
        name="jobTitle"
        onFocus={() => dispatch(touch('jobTitle'))}
      />
      {user.isThirdParty ? (
        <TextField
          data-e2e="department"
          disabled
          fullWidth
          id="department"
          inputProps={defaultInputProps}
          label="Department"
          value="Third-party user"
        />
      ) : (
        <DepartmentEdit disabled={isFormSubmitting || !canEdit} />
      )}

      <div className={classes.warningText}>
        <Typography>{warningMessage}</Typography>
      </div>

      <div className={classes.textFieldButtonContainer}>
        <FormTextField
          data-e2e="emailField"
          disabled={isFormSubmitting}
          fullWidth
          inputProps={defaultInputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: (
              <InputAdornment
                classes={{
                  root: user.emailConfirmed
                    ? classes.verified
                    : classes.unverified,
                }}
                position="end"
              >
                <CheckCircleOutlined />
              </InputAdornment>
            ),
          }}
          label="Email"
          name="email"
          validate={[required, validEmailPoliteErrorMessage]}
        />
      </div>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          color="primary"
          data-e2e="saveButton"
          disabled={!isFormDirty || !isFormValid || isFormSubmitting}
          type="submit"
        >
          Update Account Info
        </Button>
      </div>
    </form>
  );
};

const StyledForm = withStyles(styles)(FormCore);

export const ProfileInfoEditForm: any = reduxForm({
  enableReinitialize: true,
  form: profileInfoFormName,
})(StyledForm);
