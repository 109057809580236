// @flow
import globToRe from 'glob-to-regexp';
import { axiosResult, getAppSettings } from '@dealersocket/react-common';

export function mockApi(axiosMock: any): any {
  const departments = [];

  departments.push({
    name: 'Accounting',
    id: 'f11a5ad7-7819-44e1-b331-1fd07f6d786b',
  });

  departments.push({
    name: 'BDC',
    id: '1ba54488-2830-459f-b7a2-016264b2bbb8',
  });

  departments.push({
    name: 'Body shop',
    id: '15ff270a-0236-431a-8014-bb299bbc6335',
  });

  departments.push({
    name: 'Client care',
    id: 'c8b2939d-6847-4722-9830-1b11b90642e4',
  });

  departments.push({
    name: 'Corporate',
    id: '0c2aa780-3642-43ad-bd8b-76fa56315b58',
  });

  departments.push({
    name: 'F&I',
    id: 'e99312dc-df57-45da-988c-fabcba1794c7',
  });

  departments.push({
    name: 'Parts',
    id: 'b36ca799-c51c-45c2-b326-60201b868edd',
  });

  departments.push({
    name: 'Sales',
    id: 'ca6998f5-e63a-46a2-998d-656dfad98fa9',
  });

  departments.push({
    name: 'Service',
    id: '58c825b4-6a69-4ec0-bd8b-e91540ec4310',
  });

  departments.push({
    name: 'Tech support',
    id: '2cad1092-a11a-4fe1-85ca-42512cda4d33',
  });

  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/Departments*`))
    .reply(() => {
      return axiosResult(departments);
    });
}
