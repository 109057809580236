// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import type { JssClasses } from '@dealersocket/ds-ui-react/types/styles';
import { editUserInfoThunk } from '../../../state/account-settings.thunks';
import { ProfileInfoEditForm } from './profile-info-form.component';

const styles = (theme) => {
  return {
    contentPaper: {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
      padding: '20px 27px 26px 27px',
      '&.MuiPaper-elevation1': {
        border: 'solid 1px #d8d8d8',
      },
    },
    profileGroup: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  };
};

type InternalProps = {
  classes: JssClasses,
};

export type ProfileInfoEditProps = {
  selectedUser: any,
};

const ProfileInfoEditCore = (props: ProfileInfoEditProps & InternalProps) => {
  const { selectedUser, classes } = props;

  const dispatch = useDispatch();

  const onSubmit = (values: any): any => {
    dispatch(editUserInfoThunk(values));
  };

  return (
    <div>
      <Paper square className={classes.contentPaper}>
        <Grid container direction="row" justify="space-between">
          <div className={classes.profileGroup}>
            <ProfileInfoEditForm
              initialValues={selectedUser}
              onSubmit={onSubmit}
              user={selectedUser}
            />
          </div>
        </Grid>
      </Paper>
    </div>
  );
};

export const ProfileInfoComponent = withStyles(styles)(ProfileInfoEditCore);
