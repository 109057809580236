// @flow
import MockAdapter from 'axios-mock-adapter';
import { axiosHelper } from '@dealersocket/react-common';

/* eslint-disable global-require */
export function mockAxiosApi(appAxios: any): any {
  axiosHelper.setAxios(appAxios);

  const axiosMock = new MockAdapter(appAxios, { delayResponse: 800 });
  require('area/login-providers/mock/login-providers.mock-api').mockApi(
    axiosMock
  );
  require('area/account-settings/mock/user.mock-api').mockApi(axiosMock);
  require('area/scopes/mock/scopes.mock-api').mockApi(axiosMock);
  require('area/users/mock/users.mock-api').mockApi(axiosMock);
  require('area/clients/mock/clients.mock-api').mockApi(axiosMock);
  require('area/departments/mock/departments.mock-api').mockApi(axiosMock);
  require('area/dealerships/mock/dealerships.mock-api').mockApi(axiosMock);
  axiosMock.onAny().passThrough();
}
/* eslint-enable global-require */
