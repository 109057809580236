// @flow
export const fsOidcUser = {
  user: {
    profile: {
      name: 'Justin Dennison',
      given_name: 'Justin',
      family_name: 'Dennison',
      role: ['sso_internal'],
      sub: '80613f4b-b159-4cbe-be68-e1f1cfef133c',
    },
  },
};
