// @flow
export const required = (value: any) => (value ? undefined : 'Required');

export const requiredId = (value: any) => {
  return value && value.id ? undefined : 'Required';
};

export const validUrl = (value: any) =>
  value &&
  !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i.test(
    value
  )
    ? 'Invalid URL'
    : undefined;

export const validEmail = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

const validEmailCustomMessage = (message: string) => (value: any) =>
  validEmail(value) ? message : undefined;

export const validEmailPoliteErrorMessage = validEmailCustomMessage(
  'Please enter a valid email address'
);

export const dateNewerThanTwoDaysOld = (date: any) => {
  const d = new Date();
  const newDate = new Date(date);
  d.setDate(d.getDate() - 2);
  d.setUTCHours(0, 0, 0, 0);
  return date == null || newDate - d >= 0
    ? undefined
    : 'Must be newer than 2 days in the past';
};

export const between = (min: number, max: number) => (value: number) =>
  value && (value < min || value > max)
    ? `Must be between ${min} and ${max}`
    : undefined;

export const accessTokenLifetimeRange = between(120, 36000);
export const absoluteRefreshLifetimeRange = between(120, 5184000);
export const slidingRefreshLifetimeRange = between(120, 1296000);
