// @noflow
/**
 * Create the store with asynchronously loaded reducers
 */

import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { storeHelper } from '@dealersocket/react-common';
import { createRootReducer } from './reducers';

export function configureStore(initialState: any, history: any) {
  // Create the store with two middlewares
  // 1. thunk: enables redux-thunk
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [thunk, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(...enhancers)
  );
  // Extensions
  store.asyncReducers = {}; // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then((reducerModule) => {
        const newCreateRootReducer = reducerModule.default;
        const newRootReducer = newCreateRootReducer(store.asyncReducers);

        store.replaceReducer(newRootReducer);
      });
    });
  }

  storeHelper.init(store, createRootReducer);

  return store;
}
