// @flow
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  isSubmitting,
  isValid,
} from 'redux-form';
import { withStyles, StyleRules } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import {
  oneCapitalValidator,
  oneLowercaseValidator,
  oneNumberValidator,
  minLengthValidator,
  passwordsMatchValidator,
} from '@dealersocket/ds-ui-react/PasswordValidation/password-validators';
import { PasswordValidation } from '@dealersocket/ds-ui-react/PasswordValidation';
import { defaultInputProps, passwordFormName } from 'shared/constants';
import { required } from 'shared/utils/validation';
import {
  duplicatePasswordSelector,
  invalidPasswordSelector,
} from '../../../area/account-settings/state/account-settings.selectors';
import {
  setDuplicatePasswordAction,
  setInvalidPasswordAction,
} from '../../../area/account-settings/state/account-settings.actions';

const styles = (theme: Theme): StyleRules => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    marginTop: 15,
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  formControl: {
    marginBottom: 0,
  },
  validation: {
    marginBottom: 15,
  },
});

type InternalProps = {
  classes: JssClasses,
  confirmNewPasswordValue?: string,
  handleSubmit: any,
  newPasswordValue?: string,
};

export type ExternalProps = {
  requireCurrentPassword: boolean,
};

type PasswordFormProps = InternalProps & ExternalProps;

const PasswordFormCore = (props: PasswordFormProps) => {
  const {
    classes,
    confirmNewPasswordValue,
    handleSubmit,
    newPasswordValue,
    requireCurrentPassword,
  } = props;

  const [values, setValues] = useState({
    showPassword: false,
  });

  const oidcUser = useSelector((state) => state.oidc.user);

  const dispatch = useDispatch();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const formIsSubmitting = useSelector((state) =>
    isSubmitting(passwordFormName)(state)
  );

  const isPasswordChangeValid = useSelector((state) =>
    isValid(passwordFormName)(state)
  );

  const duplicatePassword = useSelector((state) =>
    duplicatePasswordSelector(state)
  );
  const invalidPassword = useSelector((state) =>
    invalidPasswordSelector(state)
  );

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit}>
        <input
          name="username"
          readOnly
          type="hidden"
          value={oidcUser.profile.preferred_username}
        />
        <FormGroup>
          {requireCurrentPassword ? (
            <FormTextField
              autoComplete="current-password"
              className={invalidPassword ? classes.formControl : undefined}
              data-e2e="currentPasswordField"
              disabled={formIsSubmitting}
              inputProps={defaultInputProps}
              label="Current password"
              name="currentPassword"
              onChange={() => {
                if (invalidPassword) {
                  dispatch(setInvalidPasswordAction(false));
                }
              }}
              required
              type="password"
              validate={[required]}
            />
          ) : null}
          {invalidPassword && (
            <Typography className={classes.error} gutterBottom>
              The current password is invalid.
            </Typography>
          )}
          <FormTextField
            autoComplete="new-password"
            autoFocus={!requireCurrentPassword}
            className={duplicatePassword ? classes.formControl : undefined}
            data-e2e="newPasswordField"
            disabled={formIsSubmitting}
            endAdornment={
              <IconButton onClick={handleClickShowPassword} tabIndex={-1}>
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            inputProps={defaultInputProps}
            label="New password"
            name="newPassword"
            onChange={() => {
              if (duplicatePassword) {
                dispatch(setDuplicatePasswordAction(false));
              }
            }}
            required
            type={values.showPassword ? 'text' : 'password'}
            validate={[
              minLengthValidator,
              oneCapitalValidator,
              oneLowercaseValidator,
              oneNumberValidator,
              required,
            ]}
          />
          {duplicatePassword && (
            <Typography className={classes.error} gutterBottom>
              You cannot reuse an old password.
            </Typography>
          )}
          {newPasswordValue && (
            <div className={classes.validation}>
              <PasswordValidation
                confirmNewPassword={confirmNewPasswordValue}
                newPassword={newPasswordValue}
                title={<Typography paragraph>Password criteria</Typography>}
              />
            </div>
          )}
          <FormTextField
            autoComplete="new-password"
            data-e2e="confirmNewPasswordField"
            disabled={formIsSubmitting}
            endAdornment={
              <IconButton onClick={handleClickShowPassword} tabIndex={-1}>
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            inputProps={defaultInputProps}
            label="Re-enter new password"
            name="confirmNewPassword"
            required
            type={values.showPassword ? 'text' : 'password'}
            validate={[passwordsMatchValidator, required]}
          />
        </FormGroup>
        <div className={classes.buttonGroup}>
          <Button
            className={classes.updatePasswordButton}
            disabled={!isPasswordChangeValid || formIsSubmitting}
            color="primary"
            type="submit"
            data-e2e="updatePasswordButton"
          >
            UPDATE PASSWORD
          </Button>
        </div>
      </form>
    </div>
  );
};

const StyledPasswordForm = withStyles(styles)(PasswordFormCore);

const PasswordForm = reduxForm({
  enableReinitialize: true,
  form: passwordFormName,
})(StyledPasswordForm);

const selector = formValueSelector(passwordFormName);

function mapStateToProps(state: any) {
  const newPasswordValue = selector(state, 'newPassword');
  const confirmNewPasswordValue = selector(state, 'confirmNewPassword');

  return {
    newPasswordValue,
    confirmNewPasswordValue,
  };
}

export const ConnectedPasswordForm: any = connect(mapStateToProps)(
  PasswordForm
);
