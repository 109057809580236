// @flow
import { createAction } from 'redux-actions';
import {
  SET_ENTITIES_LOADING,
  SET_INTENDED_ROUTE,
  SET_INTENDED_ROW,
  SET_STEP_INDEX,
} from './page.types';

export const setEntitiesLoadingAction = createAction(SET_ENTITIES_LOADING);
export const setIntendedRouteAction = createAction(SET_INTENDED_ROUTE);
export const setIntendedRowAction = createAction(SET_INTENDED_ROW);
export const setStepIndexAction = createAction(SET_STEP_INDEX);
