// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  SET_RIGHT_DRAWER_MODE,
  SET_RIGHT_DRAWER_OPEN,
  sliceName,
} from './right-drawer.types';

const initialState = {
  drawerOpen: false,
  drawerMode: 'view',
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_RIGHT_DRAWER_MODE:
      return {
        ...state,
        drawerMode: action.payload,
      };
    case SET_RIGHT_DRAWER_OPEN:
      if (state.drawerOpen !== action.payload) {
        return {
          ...state,
          drawerOpen: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
