// @flow
import { createAction } from 'redux-actions';
import {
  LOAD_DEPARTMENTS,
  LOAD_DEPARTMENTS_ERROR,
  LOAD_DEPARTMENTS_SUCCESS,
} from './department.types';

export const loadDepartmentsSuccessAction = createAction(
  LOAD_DEPARTMENTS_SUCCESS
);
export const loadDepartmentsAction = createAction(LOAD_DEPARTMENTS);
export const loadDepartmentsErrorAction = createAction(LOAD_DEPARTMENTS_ERROR);
