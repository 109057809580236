// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { productEnum } from '../../utils/enums';
import { useAsyncOperation } from '../../utils/use-async-operation';
import { required } from '../../utils/validation';
import { Timer } from '../../timer/timer.component';
import { defaultInputProps } from '../../constants';
import { Form } from '../form/form.component';

const styles = (theme) => {
  return {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    description: {
      marginBottom: 30,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    headerTitle: {
      marginBottom: 15,
    },
    secondaryButtonStyle: {
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    tooltip: {
      maxWidth: 300,
    },
    whiteText: {
      color: theme.palette.common.white,
    },
  };
};

type VerificationCodeInputProps = {
  classes: any,
  codeIsInvalid: boolean,
  isSubmitting: boolean,
  onCancel: any,
  onResend: any,
  onSubmit: any,
  product: any,
  submittedCode: string,
  username: string,
  verificationCode: string,
};

const VerificationCodeInputComponent = (props: VerificationCodeInputProps) => {
  const {
    classes,
    codeIsInvalid,
    isSubmitting,
    onCancel,
    onSubmit,
    onResend,
    product,
    submittedCode,
    username,
    verificationCode,
  } = props;

  const [disableResend, setDisableResend] = useState(false);
  const [timeoutEnd, setTimeoutEnd] = useState(0);
  const [lastSent, setLastSent] = useState();

  async function resendVerificationCode() {
    startTimer();

    onResend({
      username,
    });
  }

  const [resendVerificationCodeFunc, busy] = useAsyncOperation(
    resendVerificationCode
  );

  const startTimer = () => {
    let lastSendTime = lastSent;
    if (!lastSendTime) {
      lastSendTime = Date.now();
      setLastSent(lastSendTime);
    }
    const lastSendIso = new Date(lastSendTime).toISOString();
    lastSendTime = Date.parse(lastSendIso) + 300000;
    const currentTime = Date.now();

    if (lastSendTime - currentTime > 0) {
      setTimeoutEnd(lastSendTime);
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
        setLastSent(null);
      }, lastSendTime - currentTime);
    }
  };
  const tooltipText = (
    <Typography
      variant={TypographyVariants.Body2}
      className={classes.whiteText}
    >
      Verification code sent. You may send another code after{' '}
      <Timer timeoutEnd={timeoutEnd} /> have passed.
    </Typography>
  );

  const tooltip = (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={disableResend ? tooltipText : ''}
    >
      <div style={{ alignSelf: 'flex-end' }}>
        <Button
          className={classes.secondaryButtonStyle}
          data-e2e="resendButton"
          disabled={busy || disableResend || isSubmitting}
          onClick={resendVerificationCodeFunc}
        >
          RESEND CODE
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <>
      <Typography paragraph>
        Enter the code that was sent to your email to link this account.
      </Typography>
      <div className={classes.container}>
        <Form className={classes.form} onSubmit={onSubmit}>
          <FormGroup>
            <FormTextField
              autoComplete="one-time-code"
              autoFocus
              disabled={isSubmitting}
              fullWidth
              inputProps={defaultInputProps}
              label="Reset Code"
              name="verificationCode"
              placeholder="Enter the 6-digit code"
              required
              validate={[required]}
            />
          </FormGroup>
          <div className={classes.buttonContainer}>
            {tooltip}
            <Button data-e2e="cancelButton" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              data-e2e="sendEmailButton"
              disabled={
                isSubmitting ||
                !verificationCode ||
                (codeIsInvalid && submittedCode === verificationCode)
              }
              type="submit"
            >
              Link {productEnum(product.type)}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const verificationCode = selector(state, 'verificationCode');
  return {
    verificationCode,
  };
}

export const StyledComponent = withStyles(styles)(
  VerificationCodeInputComponent
);

export const VerificationCodeInput = connect(mapStateToProps)(StyledComponent);
