// @flow
export const filterEntities = (
  entities: any,
  filterProperty: string,
  filterTerm: string
) => {
  if (entities && filterTerm) {
    return (
      entities.filter((entity) => {
        return (
          entity[filterProperty]
            .toUpperCase()
            .search(filterTerm.toUpperCase()) >= 0
        );
      }) || []
    );
  }
  return entities || [];
};

const sortWithOptions = (entities: any, tableOptions: any) => {
  if (!entities) {
    return;
  }

  entities.sort((a, b) => {
    let entityA = a[tableOptions.sortTerm];
    let entityB = b[tableOptions.sortTerm];
    if (typeof entityA === 'string') {
      entityA = entityA.toUpperCase();
    }
    if (typeof entityB === 'string') {
      entityB = entityB.toUpperCase();
    }

    let comparison = 0;
    if (entityA > entityB) {
      comparison = 1;
    } else if (entityA < entityB) {
      comparison = -1;
    }
    return comparison;
  });
  if (tableOptions.sortOrder === 'desc') {
    entities.reverse();
  }
};

export const sortEntities = (entities: any, tableOptions: any) => {
  sortWithOptions(entities, tableOptions);
  return entities.slice(
    tableOptions.page * tableOptions.limit,
    (tableOptions.page + 1) * tableOptions.limit
  );
};

export const sortUnslicedEntities = (entities: any, tableOptions: any) => {
  sortWithOptions(entities, tableOptions);
  return entities;
};
