// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  LOAD_DEPARTMENTS,
  LOAD_DEPARTMENTS_ERROR,
  LOAD_DEPARTMENTS_SUCCESS,
  sliceName,
} from './department.types';

const initialState = {
  departments: [],
  isLoading: false,
  isError: false,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case LOAD_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.payload || state.departments,
        isLoading: false,
        isError: false,
      };

    case LOAD_DEPARTMENTS_ERROR:
      return { ...state, isLoading: false, isError: true };

    case LOAD_DEPARTMENTS:
      return { ...state, isLoading: true, isError: false };

    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
