// @flow
import { createAction } from 'redux-actions';
import {
  APPLY_FILTERS,
  CLEAR_UNAPPLIED_FILTER,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  EDIT_USER,
  LOAD_ACCOUNT_SETTINGS,
  LOAD_ACCOUNT_SETTINGS_SUCCESS,
  LOAD_USER_AND_DEALERSHIPS,
  LOAD_USER_SUCCESS,
  LOAD_USERS_SUCCESS,
  RESET_FILTERS,
  SELECT_USER,
  SET_EXPANDED_FILTER,
  SET_FILTER,
  SET_IS_BUSY,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  SET_USER_STATUS,
  SET_USER_STATUS_FAILED,
  SET_USER_STATUS_SUCCESS,
  SET_WILL_REDIRECT,
} from './users.types';

export const applyFiltersAction = createAction(APPLY_FILTERS);
export const clearUnappliedFilterAction = createAction(CLEAR_UNAPPLIED_FILTER);
export const deleteUserAction = createAction(DELETE_USER);
export const deleteUserFailedAction = createAction(DELETE_USER_FAILED);
export const deleteUserSuccessAction = createAction(DELETE_USER_SUCCESS);
export const editUserAction = createAction(EDIT_USER);
export const loadAccountSettingsAction = createAction(LOAD_ACCOUNT_SETTINGS);
export const loadAccountSettingsSuccessAction = createAction(
  LOAD_ACCOUNT_SETTINGS_SUCCESS
);
export const loadUserAndDealershipsAction = createAction(
  LOAD_USER_AND_DEALERSHIPS
);
export const loadUserSuccessAction = createAction(LOAD_USER_SUCCESS);
export const loadUsersSuccessAction = createAction(LOAD_USERS_SUCCESS);
export const resetFiltersAction = createAction(RESET_FILTERS);
export const selectUserAction = createAction(SELECT_USER);
export const setExpandedFilterAction = createAction(SET_EXPANDED_FILTER);
export const setFilterAction = createAction(SET_FILTER);
export const setIsBusyAction = createAction(SET_IS_BUSY);
export const setPageAction = createAction(SET_PAGE);
export const setPageSizeAction = createAction(SET_PAGE_SIZE);
export const setSearchTermAction = createAction(SET_SEARCH_TERM);
export const setSortOrderAction = createAction(SET_SORT_ORDER);
export const setUserStatusAction = createAction(SET_USER_STATUS);
export const setUserStatusFailedAction = createAction(SET_USER_STATUS_FAILED);
export const setUserStatusSuccessAction = createAction(SET_USER_STATUS_SUCCESS);
export const setWillRedirectAction = createAction(SET_WILL_REDIRECT);
