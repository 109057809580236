// @flow
/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { commonReducers, getHistory } from '@dealersocket/react-common';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import './shared/components/banner/state/banner.reducer';
import './shared/components/left-nav/state/left-nav.reducer';
import './shared/components/right-drawer/state/right-drawer.reducer';
import './shared/components/page/state/page.reducer';
import './shared/redux-dialog/state/redux-dialog.reducer';
import './shared/language/state/language.reducer';
import './shared/components/customer-reassignment/state/customer-reassignment.reducer';
import './area/departments/state/departments.reducer';
import './area/dealerships/state/dealerships.reducer';
import './area/users/state/users.reducer';
import './area/users/reports/state/reports.reducer';
import './area/scopes/state/scopes.reducer';
import './area/login-providers/state/login-providers.reducer';
import './area/clients/state/clients.reducer';
import './area/account-settings/state/account-settings.reducer';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export function createRootReducer(asyncReducers: any) {
  let sliceReducers = {
    form: formReducer,
    ...commonReducers,
    ...asyncReducers,
  };

  const history = getHistory();
  if (history) {
    sliceReducers = {
      // 'router' key required by 'connected-react-router'
      router: connectRouter(history),
      ...sliceReducers,
    };
  }

  return combineReducers(sliceReducers);
}
