// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import {
  filterEntities,
  sortUnslicedEntities,
} from 'shared/utils/sort-and-filter';
import {
  DELETE_SCOPE,
  DELETE_SCOPE_FAILED,
  DELETE_SCOPE_SUCCESS,
  LOAD_SCOPES_SUCCESS,
  SELECT_SCOPE,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_TERM,
  SET_SORT_ORDER,
  sliceName,
} from './scopes.types';

const initialState = {
  selectedScope: null,
  allScopes: [],
  scopes: [],
  totalScopes: 0,
  scopesLoaded: false,
  scopesPageTableOptions: {
    page: 0,
    limit: 25,
    filterTerm: '',
    sortOrder: 'asc',
    sortTerm: 'displayName',
  },
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case DELETE_SCOPE:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_SCOPE_FAILED:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_SCOPE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case LOAD_SCOPES_SUCCESS: {
      if (action.payload)
        action.payload.forEach((scope) => (scope.id = scope.name)); // eslint-disable-line

      const allScopes = sortUnslicedEntities(
        action.payload,
        state.scopesPageTableOptions
      );

      const filteredScopes = filterEntities(
        allScopes,
        'displayName',
        state.scopesPageTableOptions.filterTerm
      );

      return {
        ...state,
        scopesLoaded: true,
        allScopes,
        scopes: filteredScopes.slice(
          state.scopesPageTableOptions.page *
            state.scopesPageTableOptions.limit,
          (state.scopesPageTableOptions.page + 1) *
            state.scopesPageTableOptions.limit
        ),
        totalScopes: filteredScopes.length,
      };
    }
    case SELECT_SCOPE:
      if (state.selectedScope !== action.payload) {
        return {
          ...state,
          selectedScope: action.payload,
        };
      }
      return state;
    case SET_PAGE:
      if (action.payload !== state.scopesPageTableOptions.page) {
        return {
          ...state,
          scopesPageTableOptions: {
            ...state.scopesPageTableOptions,
            page: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    case SET_PAGE_SIZE:
      if (action.payload !== state.scopesPageTableOptions.limit) {
        return {
          ...state,
          scopesPageTableOptions: {
            ...state.scopesPageTableOptions,
            limit: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SEARCH_TERM:
      if (action.payload !== state.scopesPageTableOptions.filterTerm) {
        return {
          ...state,
          scopesPageTableOptions: {
            ...state.scopesPageTableOptions,
            filterTerm: action.payload,
            page: 0,
          },
        };
      }
      return {
        ...state,
      };
    case SET_SORT_ORDER: {
      const { sortOrder, sortTerm } = action.payload;
      if (
        state.scopesPageTableOptions.sortOrder !== sortOrder ||
        state.scopesPageTableOptions.sortTerm !== sortTerm
      ) {
        return {
          ...state,
          scopesPageTableOptions: {
            ...state.scopesPageTableOptions,
            sortOrder,
            sortTerm,
          },
        };
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
