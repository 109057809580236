// @flow
import { axiosApi, axiosHelper } from '@dealersocket/react-common';

// Removes the pragma request header before making the axois api call.
// After the call, the original value of the pragma header is restored
export function axiosNoPragma(url: string, options: any): Promise {
  const ax = axiosHelper.getAxios();
  const prevPragma = ax.defaults.headers.get.pragma;
  delete ax.defaults.headers.get.pragma;

  const promise = axiosApi(url, options);

  // Restore pragma
  ax.defaults.headers.get.pragma = prevPragma;

  return promise;
}
