// @flow
import { isMock } from '@dealersocket/react-common';
import { connect } from 'react-redux';
import { PrivateRoute } from './private-route';
import { isExternalLoginUser } from '../../utils/is-external-login-user';

function mapStateToProps(state) {
  const { user } = state.oidc;

  const isExternal = isExternalLoginUser(user);

  const isAdmin =
    user &&
    user.profile &&
    user.profile.role &&
    (user.profile.role.includes('sso_internal') ||
      user.profile.role.includes('sso_support') ||
      user.profile.role.includes('sso_data_admin'));

  const canViewUsers =
    isAdmin ||
    user.profile.role.includes('sso_dealer_group') ||
    user.profile.role.includes('sso_dealership');

  return {
    isAuthorized:
      isMock || ((!isExternal && canViewUsers) || (isExternal && isAdmin)),
  };
}

const mapDispatchToProps = {};

export const ConnectedPrivateSupportRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
