// @flow
import { createSelector } from 'reselect';

const selectDomain = (state) => state.reduxDialog;

const openSelector = createSelector(
  selectDomain,
  (state) => state.open
);

export const isOpenSelector = (state: any, dialogName: string) =>
  !!openSelector(state)[dialogName];
