// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import { clone } from '@dealersocket/react-common';
import {
  sliceName,
  LOAD_DEALERSHIPS,
  LOAD_DEALERSHIPS_SUCCESS,
  LOAD_DEALERSHIPS_ERROR,
  LOAD_DEALERSHIPS_FOR_LOGGED_IN_USER_SUCCESS,
} from './dealerships.types';

const initialState = {
  dealerships: {},
  isLoading: false,
  isError: false,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case LOAD_DEALERSHIPS:
      return { ...state, isLoading: true, isError: false };
    case LOAD_DEALERSHIPS_ERROR:
      return { ...state, isLoading: false, isError: true };
    case LOAD_DEALERSHIPS_SUCCESS:
      if (action.payload) {
        const { dealershipGroupId, dealerships } = action.payload;
        const newDealershipsState = clone(state);
        if (newDealershipsState) {
          newDealershipsState.dealerships[dealershipGroupId] = dealerships;
          return {
            ...newDealershipsState,
            isLoading: false,
            isError: false,
          };
        }
      }
      return { ...state, isLoading: false, isError: false };
    case LOAD_DEALERSHIPS_FOR_LOGGED_IN_USER_SUCCESS:
      if (action.payload) {
        const dealerships = action.payload;
        const newDealershipsState = clone(state);
        if (newDealershipsState) {
          newDealershipsState.dealerships.all = dealerships;
          return {
            ...newDealershipsState,
            isLoading: false,
            isError: false,
          };
        }
      }
      return { ...state, isLoading: false, isError: false };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
