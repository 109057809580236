// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { axiosApi, getAppSettings } from '@dealersocket/react-common';
import { ForgotPassword } from './forgot-password-component';
import { VerificationCodeInput } from './verification-code-input.component';
import { LinkProduct } from './link-product.component';
import { setBannerAction } from '../banner/state/banner.actions';

type LinkProductFormProps = {
  onCancel: any,
  onRefresh: any,
  selectedProduct: any,
};

export const ProductLinking = (props: LinkProductFormProps) => {
  const { onCancel, selectedProduct, onRefresh } = props;

  const oidcUser = useSelector((state) => state.oidc.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [username, setUsername] = useState();
  const [submittedCode, setSubmittedCode] = useState('');
  const [codeIsInvalid, setCodeIsInvalid] = useState(false);

  const oidcUserId = useSelector((state) => state.oidc.user.profile.sub);
  const dispatch = useDispatch();
  const onSendEmailSubmit = (values: any): any => {
    setIsSubmitting(true);

    const body = {
      ...values,
      product: selectedProduct.type,
    };

    axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${
        oidcUser.profile.sub
      }/LinkSendForgotPasswordCode`,
      {
        method: 'post',
        data: body,
      }
    )
      .then(() => {
        setShowCodeInput(true);
        setShowForgotPassword(false);
        setUsername(body.username);
      })
      .catch(() => {
        dispatch(
          setBannerAction({
            message: 'Unable to send forgot password code.',
            type: 'error',
            bannerAction: () => onSendEmailSubmit(values),
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onVerifyCodeSubmit = (values: any): any => {
    setIsSubmitting(true);

    const body = {
      ...values,
      username,
      product: selectedProduct.type,
      userId: oidcUser.profile.sub,
    };

    axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${
        oidcUser.profile.sub
      }/LinkVerifyCode`,
      {
        method: 'post',
        data: body,
      }
    )
      .then(() => {
        onRefresh();
        setShowCodeInput(false);
      })
      .catch(() => {
        setSubmittedCode(body.verificationCode);
        setCodeIsInvalid(true);
        dispatch(
          setBannerAction({
            message: 'The code entered is invalid. Please try again.',
            type: 'error',
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onLinkAccountSubmit = (values: any): any => {
    setIsSubmitting(true);

    const body = {
      ...values,
    };

    axiosApi(
      `${getAppSettings().ssoApiUrl}/Users/${oidcUserId}/Products/${
        selectedProduct.type
      }/LinkProductRequests`,
      {
        method: 'post',
        data: body,
      }
    )
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        if (error.response.status === 400 && !error.response.data) {
          dispatch(
            setBannerAction({
              message:
                'An error occurred. Please reload the page and try again.',
              type: 'error',
            })
          );
        } else {
          let dataMessage;
          if (
            typeof error.response.data === 'object' &&
            error.response.data !== null
          ) {
            dataMessage = JSON.stringify(error.response.data);
          } else {
            dataMessage = error.response.data;
          }
          dispatch(
            setBannerAction({
              message: `${dataMessage}`,
              type: 'error',
            })
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const getContent = () => {
    if (showForgotPassword) {
      return (
        <ForgotPassword
          product={selectedProduct}
          onSubmit={onSendEmailSubmit}
          onCancel={() => {
            setIsSubmitting(false);
            setShowForgotPassword(false);
          }}
          isSubmitting={isSubmitting}
          username={username}
        />
      );
    }

    if (showCodeInput) {
      return (
        <VerificationCodeInput
          codeIsInvalid={codeIsInvalid}
          isSubmitting={isSubmitting}
          onCancel={() => {
            setIsSubmitting(false);
            setShowCodeInput(false);
          }}
          onSubmit={onVerifyCodeSubmit}
          product={selectedProduct}
          submittedCode={submittedCode}
          username={username}
          onResend={onSendEmailSubmit}
        />
      );
    }

    return (
      <LinkProduct
        isSubmitting={isSubmitting}
        product={selectedProduct}
        onCancel={onCancel}
        onForgotPassword={(proposedUsername: string) => {
          setIsSubmitting(false);
          setUsername(proposedUsername);
          setShowForgotPassword(true);
        }}
        onSubmit={onLinkAccountSubmit}
      />
    );
  };

  return <>{getContent()}</>;
};
