// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { lazyRender } from '@dealersocket/react-common';
import { ConnectedPrivateInternalRoute } from 'shared/components/private-route/private-internal-route.container';
import { ConnectedPrivateSupportRoute } from 'shared/components/private-route/private-support-route.container';
import { getUserRole } from 'shared/utils/get-user-role';
import { ConnectedAccountSettingsPage } from './area/account-settings/account-settings-page/account-settings-page.container';

export const Routes = () => {
  const user = useSelector((state) => state.oidc.user);

  if (!user) {
    return null;
  }

  const role = getUserRole(user);

  return (
    <Switch>
      {role === '' ? (
        <Route
          exact
          path="/"
          render={() => <Redirect to="/account/settings/personalinfo" />}
        />
      ) : (
        <Route exact path="/" render={() => <Redirect to="/users" />} />
      )}
      <Route
        path="/id_token=*"
        component={lazyRender(
          () => import('area/authentication/authenticating-page'),
          'AuthenticatingPage'
        )}
      />

      <ConnectedPrivateInternalRoute
        exact
        path="/clients"
        component={lazyRender(
          () => import('area/clients/clients-page/clients-page.container'),
          'ConnectedClientsPage'
        )}
      />
      <ConnectedPrivateSupportRoute
        exact
        path="/users"
        component={lazyRender(
          () => import('area/users/users-page/users-page.container'),
          'ConnectedUsersPage'
        )}
      />
      <ConnectedPrivateSupportRoute
        exact
        path="/users/reports"
        component={lazyRender(
          () => import('area/users/reports/reports-page.js'),
          'ReportsPage'
        )}
      />

      <ConnectedPrivateSupportRoute
        path="/users/:userId"
        component={lazyRender(
          () =>
            import(
              'area/users/users-page/user-profile-page/user-profile-page.component'
            ),
          'UserProfilePage'
        )}
      />
      <ConnectedPrivateInternalRoute
        exact
        path="/scopes"
        component={lazyRender(
          () => import('area/scopes/scopes-page/scopes-page.container'),
          'ConnectedScopesPage'
        )}
      />
      <ConnectedPrivateInternalRoute
        exact
        path="/loginProviders"
        component={lazyRender(
          () =>
            import(
              'area/login-providers/login-providers-page/login-providers-page.container'
            ),
          'ConnectedLoginProvidersPage'
        )}
      />
      <Route
        path="/account/settings"
        render={() => <ConnectedAccountSettingsPage />}
      />
      <Route
        exact
        path="/forbidden"
        component={lazyRender(
          () => import('area/forbidden/forbidden-page'),
          'ForbiddenPage'
        )}
      />
      <Route
        path=""
        component={lazyRender(
          () => import('area/not-found/not-found-page'),
          'NotFoundPage'
        )}
      />
    </Switch>
  );
};
