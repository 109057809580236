// @flow
export const getUserRole = (user: any) => {
  let roles = user && user.profile.role ? user.profile.role : [];

  if (!Array.isArray(roles)) {
    roles = [roles];
  }

  return roles.find((role) => role.startsWith('sso_')) || '';
};
