// @flow
import globToRe from 'glob-to-regexp';
import { axiosResult, getAppSettings } from '@dealersocket/react-common';

export function mockApi(axiosMock: any): any {
  const users = [];
  const page = 1;
  const pageSize = 25;

  let index = (page - 1) * pageSize + 1;
  const max = index + pageSize;
  for (index; index < max; index += 1) {
    users.push({
      id: `mockuser${index}`,
      userName: index % 3 ? '' : `username${index}`,
      name:
        index % 5 ? `Mock User ${index}` : `Super Long Name Mock User ${index}`,
      firstName: `Mock`,
      lastName: `User ${index}`,
      enabled: !!(index % 7),
      userStatusDisplay: index % 6 ? 'Confirmed' : 'Force change pswd',
      created: new Date(new Date().setDate(new Date().getDate() - 5)),
      lastModified: new Date(),
      roles: index % 5 ? '' : ['imp', 'sso_internal'],
      email: index % 4 ? `email${index}@dealersocket.com` : '',
      emailConfirmed: !!(index % 2),
      products:
        index % 5
          ? [
              {
                type: 1,
                enabled: true,
                id: index,
                userName: `mockuser${index}`,
                dealerships: index % 3 ? ['154', '3'] : '',
                eulaAccepted: !!(index % 3),
                roles: index % 3 ? null : ['admin'],
              },
              {
                type: 2,
                enabled: false,
                id: index,
                userName: `mockuser${index}`,
                dealerships: index % 3 ? ['0fea20', 'a5ca'] : '',
                eulaAccepted: !!(index % 3),
                roles: index % 3 ? null : ['admin'],
              },
            ]
          : '',
      jobTitle: index % 2 ? 'Master Commander' : 'Chief Salesperson',
      defaultEnterpriseDealership:
        index % 2
          ? {
              name: 'DealerSocket',
              id: '0ba54488-2830-459f-b7a2-016264b2bbb8',
            }
          : {
              name: 'Ken Garff Honda',
              id: '0ba54488-2830-459f-b7a2-016264b2bcc3',
            },
      department:
        index % 2
          ? {
              name: 'Sales',
              id: '0ba54488-2830-459f-b7a2-016264b2bbb8',
            }
          : null,
    });
  }

  axiosMock
    .onPost(globToRe(`${getAppSettings().ssoApiUrl}/Users*`))
    .reply(() => {
      return axiosResult({
        nextPageFilter: { page: index },
        totalUsers: 100,
        users,
      });
    });

  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/Users/*`))
    .reply((config) => {
      return axiosResult(users.find((x) => config.url.includes(x.id)));
    });

  axiosMock
    .onPut(globToRe(`${getAppSettings().ssoApiUrl}/Users*`))
    .reply((config) => {
      return axiosResult(config.data);
    });

  axiosMock
    .onDelete(globToRe(`${getAppSettings().ssoApiUrl}/Users*`))
    .reply(() => {
      return axiosResult('');
    });
}
