// @flow
import { createSelector } from 'reselect';

const selectSlice = (state: any) => state.accountSettings;

export const disableResetPasswordSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.disableResetPassword;
  }
);

export const disableSendAccountSetupSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.disableSendAccountSetup;
  }
);

export const disableSaveSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.disableSave;
  }
);

export const disableUnlinkSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.disableUnlink;
  }
);

export const disableResetAccountSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.disableResetAccount;
  }
);

export const duplicatePasswordSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.duplicatePassword;
  }
);

export const flippedCardsSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.flippedCards;
  }
);

export const invalidPasswordSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.invalidPassword;
  }
);

export const isSubmittingEmailSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.isSubmittingEmail;
  }
);

export const openChangePasswordDialogSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.openChangePasswordDialog;
  }
);

export const productSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.product;
  }
);

export const uniqueUsernameSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.uniqueUsername;
  }
);

export const unlinkConfirmDialogOpenSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.unlinkConfirmDialogOpen;
  }
);

export const userSelector = createSelector(
  selectSlice,
  (slice) => {
    return slice.user;
  }
);
