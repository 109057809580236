// @flow
import { useState, useEffect, useRef } from 'react';

export type CallbackOperationCompletedType = (
  data?: any,
  error?: string
) => void;
const noop: CallbackOperationCompletedType = (): void => {};

type CallbackOperationType = (...args: any[]) => Promise<any>;

export function useAsyncOperation(
  callback: CallbackOperationType,
  completedCallback: CallbackOperationCompletedType = noop
): any {
  const callbackRef: any = useRef();
  const completedCallbackRef: any = useRef();
  const isMountedRef: any = useRef(undefined);
  const [isBusy, setIsBusy] = useState(false);
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);

  // If caller is using a locally-defined function, this will
  // keep things up to date.
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    completedCallbackRef.current = completedCallback;
  }, [completedCallback]);

  // Detect if component gets un-mounted
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  async function wrapperFunc(...args: any[]): Promise<void> {
    if (isBusy) {
      throw new Error('Request is in progress');
    }

    try {
      setIsBusy(true);
      setResult(undefined);
      setError(undefined);

      const data: any = await callbackRef.current(...args);

      if (isMountedRef.current) {
        setIsBusy(false);
        setResult(data);
        completedCallbackRef.current(data, undefined);
      }
    } catch (err) {
      if (isMountedRef.current) {
        setIsBusy(false);
        const errorMessage = err.response.data || err.message;
        setError(errorMessage);
        completedCallbackRef.current(undefined, errorMessage);
      }
    }
  }

  return [wrapperFunc, isBusy, result, error];
}
