// @flow
export function getUserProducts(user: any): any[] {
  if (user && user.products) {
    return Array.isArray(user.products) ? user.products : [user.products];
  }
  return [];
}

export function getUserDealerships(user: any): any[] {
  if (user && user.dealerships) {
    return Array.isArray(user.dealerships)
      ? user.dealerships
      : [user.dealerships];
  }
  return [];
}

export function getUsernameWithoutPrefix(username: string): string {
  const usernamePrefixSeparator = '::';
  const indexOfUsernamePrefixSeparator = username.indexOf(
    usernamePrefixSeparator
  );
  if (indexOfUsernamePrefixSeparator !== -1) {
    return username.substr(
      indexOfUsernamePrefixSeparator + usernamePrefixSeparator.length
    );
  }
  return username;
}

export function getUserDealershipProducts(user: any, productType: number) {
  return getUserDealerships(user).flatMap((dealership) =>
    dealership.dealershipProducts.filter(
      (dealershipProduct) => dealershipProduct.productInfo.type === productType
    )
  );
}
