// @flow
import { connect } from 'react-redux';
import { selectedUserSelector } from '../../users/state/users.selectors';
import { PersonalInfoPage } from './personal-info-page';

function mapStateToProps(state) {
  return {
    user: selectedUserSelector(state),
  };
}

const mapDispatchToProps = {};

export const ConnectedPersonalInfoPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfoPage);
