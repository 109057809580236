// @flow
import Axios from 'axios';
import { theme } from '@dealersocket/ds-ui-react/theme';
import { ThemeProvider } from '@dealersocket/ds-ui-react/theme/ThemeProvider';
import {
  axiosHelper,
  fetchHelper,
  getHistory,
  isMock,
  lifecycle,
  LifecycleProvider,
  setAppSettings,
  setHistory,
} from '@dealersocket/react-common';
import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from 'store';
import './index.css';
import { AppRoot } from './shared/app-root/app-root';
import { translationMessages } from './shared/i18n';
// Import Language Provider
import { LanguageProvider } from './shared/language/language-provider/language-provider';
import { mockInitialState } from './mock.initial-state';

export function main(appId: string, appSettings: any) {
  setAppSettings(appSettings);
  const appAxios = Axios.create();
  appAxios.defaults.headers.get.pragma = 'no-cache';

  axiosHelper.setAxios(appAxios);
  // inject helpers, are available to all logic
  const injectedHelpers = {
    appSettings,
    fetchApi: fetchHelper.fetchApi,
    fetchJson: fetchHelper.fetchJson,
  };

  // Use mock data
  // if (process.env.NODE_ENV === 'development') {
  if (isMock) {
    // Use mock data
    require('./mock').mockAxiosApi(appAxios); // eslint-disable-line global-require
  }
  // }

  const afterLogin = (userPromise, returnHash) => {
    userPromise
      .then(() => {
        getHistory().replace(returnHash || '/');
      })
      .catch((reason) => console.error(reason)); // eslint-disable-line no-console
  };

  // lifecycle.init must be called before before the hashHistory is created.
  // redux-oidc was designed to work with browserHistory and not with hashHistory.
  // Therefore lifecycle.init intercepts the hash route '#id_token' before the hashHistory is created.
  // Once created the hash will change to '#/id_token' which could not be processed by the userManager.
  lifecycle.init({ appId: 'EnterpriseAdmin', appSettings, afterLogin });

  const history = setHistory(createHashHistory());

  // Create redux store with history
  let initialState: any = {};

  if (isMock) {
    initialState = mockInitialState;
  }

  const store: any = configureStore(initialState, history, injectedHelpers);

  const MOUNT_NODE = document.getElementById('root');
  if (!MOUNT_NODE) {
    return;
  }

  const setThemeBreakPoints = () => {
    (theme: any).breakpoints.values.sm = 601;
    (theme: any).breakpoints.values.md = 1024;
    (theme: any).breakpoints.values.lg = 1440;
    (theme: any).breakpoints.values.xl = 1920;
  };

  theme.overrides = {
    ...theme.overrides,
    MuiFormControl: {
      ...(theme.overrides && theme.overrides.MuiFormControl),
      root: {
        ...(theme.overrides &&
          theme.overrides.MuiFormControl &&
          theme.overrides.MuiFormControl.root),
        [theme.breakpoints.down('xs')]: { fontSize: 16 },
        height: 55,
      },
    },
    MuiInput: {
      ...(theme.overrides && theme.overrides.MuiInput),
      input: {
        [theme.breakpoints.down('xs')]: { fontSize: 16 },
      },
    },
    MuiSelect: {
      ...(theme.overrides && theme.overrides.MuiSelect),
      root: {
        ...(theme.overrides &&
          theme.overrides.MuiSelect &&
          theme.overrides.MuiSelect.root),
        [theme.breakpoints.down('xs')]: { fontSize: 16 },
      },
    },
    MuiTab: {
      root: {
        ...(theme.overrides &&
          theme.overrides.MuiTab &&
          theme.overrides.MuiTab.root),
        border: 'none',
        '&:first-child': {
          borderLeftWidth: 0,
        },
        [theme.breakpoints.up('lg')]: {
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:not(.Mui-selected)': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          },
          '&$selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:not': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          },
        },
        [theme.breakpoints.down('md')]: {
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            '&:not(.Mui-selected)': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary.main,
            },
          },
          '&$selected': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            '&:not': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };

  const render = () => {
    setThemeBreakPoints();

    ReactDOM.render(
      <Provider store={store}>
        <LifecycleProvider appId={appId} appSettings={appSettings}>
          <ThemeProvider theme={theme}>
            <LanguageProvider
              messages={translationMessages}
              locale={navigator.language}
            >
              <ConnectedRouter history={history}>
                <AppRoot />
              </ConnectedRouter>
            </LanguageProvider>
          </ThemeProvider>
        </LifecycleProvider>
      </Provider>,
      MOUNT_NODE
    );
  };

  if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      module.hot.accept('./shared/app-root/app-root', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      });
    }
  }

  render();
}
