// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Banner } from '@dealersocket/ds-ui-react/Banner';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';

const styles = (theme) => {
  return {
    banner: {
      borderBottom: `solid 1px ${theme.palette.grey[400]}`,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    error: {
      color: theme.palette.error.main,
      marginRight: 4,
    },
    messageContent: {
      display: 'flex',
      flexDirection: 'row',
    },
  };
};

export type BannerProps = {
  bannerAction?: any,
  clearBannerAction?: any,
  isOpen: boolean,
  message?: string,
  type?: string,
};

type InternalProps = {
  classes: JssClasses,
};

const BannerComponent = (props: BannerProps & InternalProps) => {
  const {
    bannerAction,
    clearBannerAction,
    isOpen,
    message,
    type,
    classes,
  } = props;

  function bannerFunction() {
    return bannerAction;
  }

  const buttonText = () => {
    switch (type) {
      case 'report':
        return 'View Report';
      default:
        return 'Try Again';
    }
  };

  const dispatch = useDispatch();

  return (
    <div className={classes.banner}>
      <Banner
        message={
          <div
            className={classes.messageContent}
            data-e2e="bannerMessageContent"
          >
            {type === 'error' && (
              <Typography className={classes.error} display="inline">
                Something went wrong.
              </Typography>
            )}
            <Typography variant={TypographyVariants.Body1}>
              {message}
            </Typography>
          </div>
        }
        leftButton={
          bannerAction ? (
            <Button
              id="actionButton"
              onClick={() => {
                dispatch(bannerFunction());
                clearBannerAction();
              }}
              data-e2e="actionButton"
            >
              {buttonText()}
            </Button>
          ) : null
        }
        rightButton={
          <Button
            id="closeButton"
            onClick={() => clearBannerAction()}
            data-e2e="closeButton"
          >
            Close
          </Button>
        }
        isOpen={isOpen}
      />
    </div>
  );
};

export const StyledBanner = withStyles(styles)(BannerComponent);
