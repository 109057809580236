// @flow
import { getAppSettings } from '@dealersocket/react-common';
import { axiosNoPragma } from './axios-no-pragma';

// Checks to see if the given user has events assigned for the given
// site Id (dealership)
export function getCrmHasUserEvents(siteId: string, crmUsername: string) {
  return axiosNoPragma(
    `${
      getAppSettings().crmApiUrl
    }/dealershipusers/site/${siteId}/username/${crmUsername}/hasuserevents`
  );
}
// Calls the CRM API to check if the given CRM user ID is the default
// user for the given site ID (dealership)
export function getCrmIsDefaultDealershipUser(siteId: string, crmId: string) {
  return axiosNoPragma(
    `${
      getAppSettings().crmApiUrl
    }/dealershipusers/site/${siteId}/dealershipuser/${crmId}/isDefaultDealershipUser`,
    { headers: { dealershipid: siteId } }
  );
}

export function getCrmDealershipUser(siteId: string, crmId: string) {
  return axiosNoPragma(
    `${
      getAppSettings().crmApiUrl
    }/dealershipusers/site/${siteId}/dealershipuser/${crmId}`
  );
}

export function postCrmDealershipUser(
  siteId: string,
  crmId: string,
  dealershipUser: any // DealershipUser object
) {
  return axiosNoPragma(
    `${
      getAppSettings().crmApiUrl
    }/dealershipusers/site/${siteId}/dealershipuser/${crmId}`,
    {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        dealershipid: siteId,
      },
      data: JSON.stringify(dealershipUser),
    }
  );
}
