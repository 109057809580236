// @flow
import { createAction } from 'redux-actions';
import {
  CHECK_USERNAME,
  CHECK_USERNAME_FAILED,
  CHECK_USERNAME_SUCCESS,
  ENABLE_RESET_PASSWORD,
  RESET_ACCOUNT,
  RESET_ACCOUNT_FAILED,
  RESET_PASSWORD,
  SEND_ACCOUNT_SETUP,
  SEND_ACCOUNT_SETUP_FAILED,
  SEND_ACCOUNT_SETUP_SUCCESS,
  SEND_EMAIL_VERIFICATION,
  SEND_EMAIL_VERIFICATION_FAILED,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SET_DUPLICATE_PASSWORD,
  SET_INVALID_PASSWORD,
} from './account-settings.types';

export const checkUsernameAction = createAction(CHECK_USERNAME);
export const checkUsernameFailedAction = createAction(CHECK_USERNAME_FAILED);
export const checkUsernameSuccessAction = createAction(CHECK_USERNAME_SUCCESS);
export const enableResetPasswordAction = createAction(ENABLE_RESET_PASSWORD);
export const resetAccountAction = createAction(RESET_ACCOUNT);
export const resetAccountFailedAction = createAction(RESET_ACCOUNT_FAILED);
export const resetPasswordAction = createAction(RESET_PASSWORD);
export const sendAccountSetupAction = createAction(SEND_ACCOUNT_SETUP);
export const sendAccountSetupFailedAction = createAction(
  SEND_ACCOUNT_SETUP_FAILED
);
export const sendAccountSetupSucesssAction = createAction(
  SEND_ACCOUNT_SETUP_SUCCESS
);
export const sendEmailVerificationAction = createAction(
  SEND_EMAIL_VERIFICATION
);
export const sendEmailVerificationFailedAction = createAction(
  SEND_EMAIL_VERIFICATION_FAILED
);
export const sendEmailVerificationSuccessAction = createAction(
  SEND_EMAIL_VERIFICATION_SUCCESS
);
export const setDuplicatePasswordAction = createAction(SET_DUPLICATE_PASSWORD);
export const setInvalidPasswordAction = createAction(SET_INVALID_PASSWORD);
