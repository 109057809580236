// @flow
import { DEFAULT_LOCALE } from 'shared/i18n';
import { storeHelper } from '@dealersocket/redux-utils';
import { CHANGE_LOCALE, sliceName } from './language.types';

const initialState = {
  locale: DEFAULT_LOCALE,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
