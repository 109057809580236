// @flow
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme, withStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import WarningIcon from '@material-ui/icons/Warning';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Avatar } from '@dealersocket/ds-ui-react/Avatar';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import type { JssClasses } from '@dealersocket/ds-ui-react/types';
import { maxAllowedBytes, maxAllowedMegaBytes } from 'shared/constants';
import { CustomCropper } from 'shared/components/cropper/cropper-component';
import { loadAccountsSettingsThunk } from 'area/users/state/users.thunks';
import { uploadProfileImageThunk } from '../../state/account-settings.thunks';

const styles = (theme) => {
  return {
    avatarGroup: {
      marginBottom: 20,
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
      },
    },
    avatarNoHoverRoot: {
      fontSize: 50,
      height: 135,
      width: 135,
      [theme.breakpoints.down('md')]: {
        fontSize: 35,
        height: 100,
        width: 100,
      },
    },
    avatarRoot: {
      fontSize: 50,
      height: 135,
      width: 135,
      '&:hover': {
        opacity: 0.0,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 35,
        height: 100,
        width: 100,
      },
    },
    cameraAvatar: {
      position: 'absolute',
    },
    cameraAvatarRoot: {
      height: 135,
      width: 135,
      [theme.breakpoints.down('md')]: {
        height: 100,
        width: 100,
      },
    },
    cameraIcon: {
      fontSize: 50,
      [theme.breakpoints.down('md')]: {
        fontSize: 35,
      },
    },
    divEmailPadding: {
      paddingBottom: 20,
    },
    divJobTitlePadding: {
      paddingBottom: 34.5,
    },
    divNamePadding: {
      paddingBottom: 50,
    },
    errorGroup: {
      alignItems: 'center',
      color: theme.ds.palette.danger.main,
      display: 'flex',
      flexDirection: 'column',
    },
    profileGroup: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 40,
      paddingTop: 30,
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 10,
        paddingTop: 10,
      },
    },
    uploadGroup: {
      cursor: 'pointer',
      outlineWidth: 0,
      position: 'relative',
    },
    uploadInput: {
      display: 'none',
    },
    userInfoGroup: {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        marginLeft: 40,
      },
    },
    warningIcon: {
      fontSize: 20,
    },
  };
};

type ExternalProps = {
  user: any,
};

type InternalProps = {
  classes: JssClasses,
};

const AccountSettingsProfileComponent = (
  props: InternalProps & ExternalProps
) => {
  const { classes, user } = props;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const [uploadImg, setUploadImg] = useState();
  const [removeHover, setRemoveHover] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayCropper, setDisplayCropper] = useState(false);

  const displayUserInfo = !md || (md && !displayCropper);

  const fileInput = useRef(null);

  const triggerFileUpload = () => {
    setDisplayError(false);
    setRemoveHover(true);
    fileInput.current.click();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > maxAllowedBytes) {
        setDisplayError(true);
        return;
      }

      const reader = new FileReader();

      reader.addEventListener('load', () => setUploadImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setDisplayCropper(true);
    }
    e.target.value = null;
  };

  const dispatch = useDispatch();

  const uploadImage = (image: File) => {
    dispatch(uploadProfileImageThunk(user.id, image)).then(() => {
      user.profilePicture = null;
      setDisplayCropper(false);
      dispatch(loadAccountsSettingsThunk());
    });
  };

  const onCropperCancel = () => {
    setUploadImg(null);
    setDisplayCropper(false);
  };

  let initials = '--';

  if (user) {
    let firstName = '-';
    let lastName = '-';
    if (user.firstName) {
      ({ firstName } = user);
    }

    if (user.lastName) {
      ({ lastName } = user);
    }

    initials = firstName[0] + lastName[0];
  }

  return (
    <div className={classes.profileGroup}>
      {!displayCropper ? (
        <div className={classes.avatarGroup}>
          <div
            className={classes.uploadGroup}
            onClick={triggerFileUpload}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                triggerFileUpload();
              }
            }}
            onMouseEnter={() => {
              setRemoveHover(false);
            }}
            role="button"
            tabIndex={0}
          >
            <Avatar
              data-e2e="uploadProfileButton"
              size="large"
              classes={{
                root: classes.cameraAvatarRoot,
              }}
              className={classes.cameraAvatar}
            >
              <PhotoCamera className={classes.cameraIcon} />
            </Avatar>
            <Avatar
              classes={{
                root: removeHover
                  ? classes.avatarNoHoverRoot
                  : classes.avatarRoot,
              }}
              data-e2e="profilePicture"
              src={user && user.profilePicture}
            >
              {initials}
            </Avatar>
          </div>
          <input
            accept=".jpg,.gif,.png"
            className={classes.uploadInput}
            name="fileInput"
            onChange={onSelectFile}
            ref={fileInput}
            type="file"
          />
        </div>
      ) : (
        <CustomCropper
          setCroppedImage={uploadImage}
          onCancel={onCropperCancel}
          urlImage={uploadImg}
        />
      )}
      {displayError && (
        <div className={classes.errorGroup}>
          <WarningIcon className={classes.warningIcon} color="inherit" />
          <Typography color="inherit" variant={TypographyVariants.H6}>
            Picture is too large
          </Typography>
          <Typography color="inherit">
            Must be under {maxAllowedMegaBytes} MB
          </Typography>
        </div>
      )}

      {displayUserInfo && (
        <div className={classes.userInfoGroup} data-e2e="userInfoGroup">
          {user && user.name ? (
            <Typography variant={TypographyVariants.H4}>{user.name}</Typography>
          ) : (
            <div className={classes.divNamePadding} />
          )}
          {user && user.jobTitle ? (
            <Typography variant={TypographyVariants.Body1}>
              {user.jobTitle}
            </Typography>
          ) : (
            <div className={classes.divJobTitlePadding} />
          )}
          {user && user.email ? (
            <Typography variant={TypographyVariants.Body1}>
              {user.email}
            </Typography>
          ) : (
            <div className={classes.divEmailPadding} />
          )}
        </div>
      )}
    </div>
  );
};

export const AccountSettingsProfile = withStyles(styles)(
  AccountSettingsProfileComponent
);
