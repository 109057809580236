// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import { CLOSE_DIALOG, OPEN_DIALOG, sliceName } from './redux-dialog.types';

const initialState = {
  open: {},
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case CLOSE_DIALOG: {
      const open = {
        ...state.open,
      };
      delete open[action.payload];
      return { ...state, open };
    }
    case OPEN_DIALOG: {
      const open = {
        ...state.open,
        [action.payload]: true,
      };
      return {
        ...state,
        open,
      };
    }
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
