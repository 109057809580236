// @flow
import globToRe from 'glob-to-regexp';
import { axiosResult, getAppSettings } from '@dealersocket/react-common';
import { fsUser } from './user.mock-data';

export function mockApi(axiosMock: any): any {
  let lastUpdated = null;

  axiosMock
    .onGet(globToRe(`${getAppSettings().ssoApiUrl}/Users/${fsUser.id}`))
    .reply(() => {
      const user = fsUser;

      if (lastUpdated) {
        return axiosResult(lastUpdated);
      }
      return axiosResult(user);
    });

  axiosMock
    .onPut(globToRe(`${getAppSettings().ssoApiUrl}/Users/${fsUser.id}`))
    .reply((config) => {
      lastUpdated = JSON.parse(config.data);
      return axiosResult(config.data);
    });
}
