// @flow
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import { productEnum } from '../../utils/enums';
import { required } from '../../utils/validation';
import { defaultInputProps } from '../../constants';
import { Form } from '../form/form.component';

const styles = () => {
  return {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    textFieldIcon: {
      marginRight: 5,
    },
  };
};

type LinkProductProps = {
  classes: any,
  isSubmitting: boolean,
  onCancel: any,
  onForgotPassword: any,
  onSubmit: any,
  product: any,
};

const LinkProductComponent = (props: LinkProductProps) => {
  const {
    classes,
    isSubmitting,
    onCancel,
    onForgotPassword,
    onSubmit,
    product,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const selector = formValueSelector('pageForm');
  const username = useSelector((state) => selector(state, 'username'));

  return (
    <>
      <Typography paragraph>
        Enter your {productEnum(product.type)} username and password to link
        this account.
      </Typography>
      <div className={classes.container}>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <FormTextField
              autoComplete="username"
              autoFocus
              data-e2e="usernameField"
              disabled={isSubmitting}
              inputProps={defaultInputProps}
              label={`${productEnum(product.type)} username`}
              name="username"
              required
              startAdornment={<PersonIcon className={classes.textFieldIcon} />}
              validate={[required]}
            />
            <FormTextField
              autoComplete="current-password"
              data-e2e="passwordField"
              disabled={isSubmitting}
              endAdornment={
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
              inputProps={defaultInputProps}
              label={`${productEnum(product.type)} password`}
              name="password"
              required
              startAdornment={<VpnKeyIcon className={classes.textFieldIcon} />}
              type={showPassword ? 'text' : 'password'}
              validate={[required]}
            />
          </FormGroup>
          <div className={classes.buttonContainer}>
            <Button
              data-e2e="forgotPasswordButton"
              disabled={isSubmitting}
              onClick={() => {
                onForgotPassword(username);
              }}
            >
              Forgot Password
            </Button>
            <Button data-e2e="cancelButton" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              data-e2e="linkAccountButton"
              disabled={isSubmitting}
              type="submit"
            >
              Link Account
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export const LinkProduct = withStyles(styles)(LinkProductComponent);
