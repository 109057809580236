// @flow
import React, { useEffect, useState } from 'react';

type ExternalProps = {
  timeoutEnd: number,
};

export function Timer({ timeoutEnd }: ExternalProps) {
  const calculateTimeRemaining = () => {
    const difference = timeoutEnd - Date.now();
    let timeRemaining = {};

    if (difference > 0) {
      timeRemaining = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeRemaining;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timerFunc = setTimeout(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearTimeout(timerFunc);
    };
  });

  const timerComponents: any = [];

  Object.keys(timeRemaining).forEach((timeInterval) => {
    if (!timeRemaining[timeInterval]) {
      return;
    }

    timerComponents.push(
      <span key={timeInterval}>
        {timeRemaining[timeInterval]} {timeInterval}{' '}
      </span>
    );
  });

  return timerComponents;
}
