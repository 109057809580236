// @flow
export const sliceName = 'users';

const prefix = `app/${sliceName}/`;
export const APPLY_FILTERS: string = `${prefix}APPLY_FILTERS`;
export const CLEAR_UNAPPLIED_FILTER: string = `${prefix}CLEAR_UNAPPLIED_FILTER`;
export const DELETE_USER: string = `${prefix}DELETE_USER`;
export const DELETE_USER_FAILED: string = `${prefix}DELETE_USER_FAILED`;
export const DELETE_USER_SUCCESS: string = `${prefix}DELETE_USER_SUCCESS`;
export const EDIT_USER: string = `${prefix}EDIT_USER`;
export const LOAD_ACCOUNT_SETTINGS: string = `${prefix}LOAD_ACCOUNT_SETTINGS`;
export const LOAD_ACCOUNT_SETTINGS_SUCCESS: string = `${prefix}LOAD_ACCOUNT_SETTINGS_SUCCESS`;
export const LOAD_USER_AND_DEALERSHIPS: string = `${prefix}LOAD_USER_AND_DEALERSHIPS`;
export const LOAD_USER_SUCCESS: string = `${prefix}LOAD_USER_SUCCESS`;
export const LOAD_USERS_SUCCESS: string = `${prefix}LOAD_USERS_SUCCESS`;
export const RESET_FILTERS: string = `${prefix}RESET_FILTERS`;
export const SELECT_USER: string = `${prefix}SELECT_USER`;
export const SET_EXPANDED_FILTER: string = `${prefix}SET_EXPANDED_FILTER`;
export const SET_FILTER: string = `${prefix}SET_FILTER`;
export const SET_IS_BUSY: string = `${prefix}SET_IS_BUSY`;
export const SET_PAGE: string = `${prefix}SET_PAGE`;
export const SET_PAGE_SIZE: string = `${prefix}SET_PAGE_SIZE`;
export const SET_SEARCH_TERM: string = `${prefix}SET_SEARCH_TERM`;
export const SET_SORT_ORDER: string = `${prefix}SET_SORT_ORDER`;
export const SET_USER_STATUS: string = `${prefix}SET_USER_STATUS`;
export const SET_USER_STATUS_FAILED: string = `${prefix}SET_USER_STATUS_FAILED`;
export const SET_USER_STATUS_SUCCESS: string = `${prefix}SET_USER_STATUS_SUCCESS`;
export const SET_WILL_REDIRECT: string = `${prefix}SET_WILL_REDIRECT`;
