// @flow
export const sliceName = 'reports';

const prefix = `app/${sliceName}/`;
export const APPLY_FILTERS: string = `${prefix}APPLY_FILTERS`;
export const CLEAR_UNAPPLIED_FILTER: string = `${prefix}CLEAR_UNAPPLIED_FILTER`;
export const LOAD_USERS_SUCCESS: string = `${prefix}LOAD_USERS_SUCCESS`;
export const SET_IS_BUSY: string = `${prefix}SET_IS_BUSY`;
export const SET_PAGE: string = `${prefix}SET_PAGE`;
export const SET_PAGE_SIZE: string = `${prefix}SET_PAGE_SIZE`;
export const SET_SEARCH_TERM: string = `${prefix}SET_SEARCH_TERM`;
export const SET_SORT_ORDER: string = `${prefix}SET_SORT_ORDER`;
export const RESET_FILTERS: string = `${prefix}RESET_FILTERS`;
export const SET_EXPANDED_FILTER: string = `${prefix}SET_EXPANDED_FILTER`;
export const SET_FILTER: string = `${prefix}SET_FILTER`;
