// @flow
import { AnyAction } from 'redux';
import { storeHelper } from '@dealersocket/redux-utils';
import { sliceName, CLEAR_BANNER, SET_BANNER } from './banner.types';

const initialState = {
  message: null,
  bannerAction: null,
  type: null,
  isOpen: false,
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case CLEAR_BANNER:
      return {
        ...state,
        message: null,
        bannerAction: null,
        type: null,
        isOpen: false,
      };
    case SET_BANNER:
      return {
        ...state,
        message: action.payload.message,
        bannerAction: action.payload.bannerAction,
        type: action.payload.type,
        isOpen: true,
      };
    default:
      return state;
  }
}

const sliceInfo = {
  slicePath: sliceName,
  sliceName,
};

Object.assign(reducer, sliceInfo);

storeHelper.mergeTopReducer(reducer);
