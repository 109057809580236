// @flow
import { createAction } from 'redux-actions';
import {
  TOGGLE_LEFTNAV,
  SET_LEFTNAV_CHANGED_BY_USER,
  TOGGLE_LEFTNAV_EXPANDED,
} from './left-nav.types';

export const toggleLeftNavOpenAction = createAction(TOGGLE_LEFTNAV);
export const setLeftNavChangedByUserAction = createAction(
  SET_LEFTNAV_CHANGED_BY_USER
);
export const setLeftNavExpandedAction = createAction(TOGGLE_LEFTNAV_EXPANDED);
